import React from "react";

import { Container } from "./styles";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: "danger" | "warning" | "info" | "success";
}

const Square: React.FC<Props> = ({ children, color = "info", ...rest }) => {
    const colors = {
        danger: "#FF4370",
        warning: "",
        info: "",
        success: "",
    };

    return (
        <Container color={colors[color]} {...rest}>
            {children}
        </Container>
    );
};

export default Square;
