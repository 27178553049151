import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 66px);
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper-title {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .wrapper-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  .wrapper-text {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wrapper-button {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;
