import { createSelector } from "reselect";

import { getElements } from "../common/selectors";

import { ApplicationState } from "./../../index";
import { Chat } from "./types";

export const stateChats = (state: ApplicationState) => getElements<Chat>(state.chats);
export const chatsById = (state: ApplicationState) => state.chats.byId;
export const paginateChatsById = (state: ApplicationState) => state.chats.paginateById;

export const dataChats = createSelector(stateChats, (chats) => {
    return chats.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
});

export const dataChatsGroups = createSelector(stateChats, (chats) => {
    return chats.filter((chat) => chat.isGroup).sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
});

export const privateChatsWithLastMessage = createSelector(stateChats, (chats) =>
    chats.filter((item) => item.lastMessageId && !item.isGroup),
);

export const chatsThatAreGroups = createSelector(stateChats, (chats) => chats.filter((item) => item.isGroup));

export const chatsCountWithLastMessage = createSelector(
    stateChats,
    (chats) => chats.filter((item) => item.lastMessageId).length,
);

export const getCurrentContactId = (state: ApplicationState) => state.chats.currentContact;

export const getDetailsChatById = (state: ApplicationState, chatId: string) => state.chats.byId[chatId];

export const getPaginateByChatId = createSelector(
    paginateChatsById,
    (_, chatId) => chatId,
    (chats, chatId) => chats[chatId],
);

export const unreadAllUserAuthenticated = createSelector(
    stateChats,
    (_, userId) => userId,
    (chats, userId) =>
        chats.reduce(
            (acc, cur: any) => acc + (cur.contacts.find((item) => item.id === userId)?.informations?.unread || 0),
            0,
        ),
);
