import styled from "styled-components";

interface Props {
    sizeLetter: "sm" | "lg";
}

export const Container = styled.div<Props>`
    min-width: ${({ sizeLetter }) => sizeLetter === "lg" ? "50px" : "30px" };
    min-height: ${({ sizeLetter }) => sizeLetter === "lg" ? "50px" : "30px" };
    max-width: ${({ sizeLetter }) => sizeLetter === "lg" ? "50px" : "30px" };
    max-height: ${({ sizeLetter }) => sizeLetter === "lg" ? "50px" : "30px" };

    text-transform: uppercase;

    border-radius: 50%;

    background-color: #ffffff;
    border: 1px solid #ccc;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: ${({ sizeLetter }) => sizeLetter === "lg" ? "20px" : "12px" };
`;
