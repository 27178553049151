import React from "react";

import { Container } from "./styles";

interface Props {}

const Unread: React.FC<Props> = ({ children }) => {
    return <Container> {children} </Container>;
};

export default Unread;
