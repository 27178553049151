export enum types {
    MESSAGES_LOAD = "messages/MESSAGES_LOAD",
    MESSAGES_LOAD_SUCCESS = "messages/MESSAGES_LOAD_SUCCESS",
    MESSAGES_LOAD_FAILURE = "messages/MESSAGES_LOAD_FAILURE",

    MESSAGES_CREATE = "messages/MESSAGES_CREATE",
    MESSAGES_CREATE_SUCCESS = "messages/MESSAGES_CREATE_SUCCESS",
    MESSAGES_CREATE_FAILURE = "messages/MESSAGES_CREATE_FAILURE",

    MESSAGES_UPDATED = "messages/MESSAGES_UPDATED",
    MESSAGES_UPDATED_SUCCESS = "messages/MESSAGES_UPDATED_SUCCESS",
    MESSAGES_UPDATED_FAILURE = "messages/MESSAGES_UPDATED_FAILURE",

    MESSAGES_DESTROY = "messages/MESSAGES_DESTROY",
    MESSAGES_DESTROY_SUCCESS = "messages/MESSAGES_DESTROY_SUCCESS",
    MESSAGES_DESTROY_FAILURE = "messages/MESSAGES_DESTROY_FAILURE",

    MESSAGES_LOAD_MORE = "messages/MESSAGES_LOAD_MORE",
    MESSAGES_LOAD_MORE_SUCCESS = "messages/MESSAGES_LOAD_MORE_SUCCESS",
    MESSAGES_LOAD_MORE_FAILURE = "messages/MESSAGES_LOAD_MORE_FAILURE",

    MESSAGES_ON_SOCKET = "@messages/MESSAGES_ON_SOCKET",
}

export interface Contact {
    id: string;
    name: string;
    phone: string;
    email: string;
    status: string;
    password: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface File {
    id: string;
    filename: string;
    messageId: string;
    mimetype: string;
    size: string;
    url: string;
    updatedAt: Date;
    createdAt: Date;
}

export interface Message {
    id: string;
    contactId: string;
    chatId: string;
    contact: Contact;
    fileId: string;
    file: File;
    text: string;
    sent: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface StateMessages {
    allId: string[];
    byId: { [key: string]: any };
}
