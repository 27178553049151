import { ApplicationState } from "./../../index";
import { put, call, take, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import { types } from "./types";

import api from "../../../service/api";

import history from "../../../utils/history";

// selector
import { isContactSynced } from "../contacts/selectors";
// actions
import { actions as actionsContacts } from "../contacts/actions";
// types
import { types as typesContacts } from "../contacts/types";

import { createSocketConnection, createSocketChannel } from "../../../service/socket";

function* load() {
  try {  
    const response = yield call(api.get, "/chats/my", {});

    yield put({ type: types.CHATS_LOAD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.CHATS_LOAD_FAILURE });
  }
}

function* show(action) {
  const { type, payload } = action;

  try {
    const response = yield call(api.get, `/chats/${payload}`, {
      params: {
        include: ["contacts"],
      },
    });

    yield put({ type: types.CHATS_SHOW_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.CHATS_SHOW_FAILURE });
  }
}

function* create(action) {
  const { type, payload } = action;

  try {
    const response = yield call(api.post, "/chats", payload);

    yield call(toast.success, "Grupo criado com sucesso!", {
      onClose: () => {
        return history.push("/groups", { noGroups: false });
      },
    });

    yield put({ type: types.CHATS_CREATE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.CHATS_CREATE_FAILURE });
  }
}

function* update(action) {
  const { type, payload } = action;
  const currentState = history.location.pathname;

  const chatId = payload.id;

  try {
    const response = yield call(api.put, `/chats/${chatId}`, payload);

    yield call(toast.success, "Chat atualizado com sucesso", {
      onClose: () => {
        if (currentState == `/groups/${chatId}/edit`) {
          return history.push(`/groups`);
        }
        return;
      },
    });

    yield put({ type: types.CHATS_UPDATED_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.CHATS_UPDATED_FAILURE });
  }
}

function* destroy(action) {
  const { type, payload } = action;

  try {
    const response = yield call(api.delete, `/chats/${payload}`);

    if (response?.data?.status === 500) {
      yield call(toast.warn, "Nao foi possivel excluir, exclua os grupos primeiro!");
      return;
    }

    yield call(toast.success, "Chat deletado com sucesso", {
      onClose: () => {
        return history.push(`/groups`);
      },
    });

    yield put({ type: types.CHATS_DESTROY_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.CHATS_DESTROY_FAILURE });
  }
}

function* addMember(action) {
  const { payload } = action;

  try {
    const response = yield call(api.post, "/chats/add-member", payload);

    // TODO: verifica quando retornar error na requisição

    yield call(toast.success, "Novo contato adicionado", {
      onClose: () => {
        return history.push(`/chat-info/${payload.chatId}`);
      },
    });

    yield put({
      type: types.CHATS_ADD_MEMBER_SUCCESS,
      payload: {
        chatId: payload.chatId,
        contacts: response.data,
      },
    });
  } catch (error) {
    yield put({ type: types.CHATS_ADD_MEMBER_FAILURE });
  }
}
function* removeMember(action) {
  const { payload } = action;

  const [contactId] = payload.contactIds;

  try {
    const response = yield call(api.post, "/chats/remove-member", payload);

    // TODO: verifica quando retornar error na requisição

    yield call(toast.success, "Contato removido");

    yield put({
      type: types.CHATS_REMOVE_MEMBER_SUCCESS,
      payload: {
        chatId: payload.chatId,
        contactId,
      },
    });
  } catch (error) {
    yield put({ type: types.CHATS_REMOVE_MEMBER_FAILURE });
  }
}

function* setCurrentContact(action) {
  const { type, payload } = action;

  const { contactId, chatId } = payload;

  const isContact = yield select((state: ApplicationState) => isContactSynced(state, contactId));

  if (!isContact && contactId) {
    yield put(actionsContacts.show(contactId));

    yield take(typesContacts.CONTACTS_SHOW_SUCCESS);
  }

  yield put({ type: types.SET_CURRENT_CONTACT_SUCCESS, payload: contactId });

  yield history.push(`/chat/${chatId}`);
}

function* setPageByChatId(action) {
  const { type, payload } = action;

  yield put({ type: types.SET_PAGE_SUCCESS, payload });
}

function* watchOnChats() {
  const socket = yield call(createSocketConnection);
  const socketChannel = yield call(createSocketChannel, socket, "chats");

  while (true) {
    try {
      const payload = yield take(socketChannel);

      if (payload.event === "removed") {
        yield put({ type: types.CHATS_REMOVED_SOCKET, payload: payload.id });

        continue;
      }

      if (payload.event === "added") {
        yield put({ type: types.CHATS_ADDED_SOCKET, payload: payload });

        continue;
      }

      yield put({ type: types.CHATS_ON_SOCKET, payload });
    } catch (err) {
      console.log("[error-socket]::", JSON.stringify(err, null, 4));
    }
  }
}

export default {
  load,
  show,
  create,
  update,
  destroy,
  setCurrentContact,
  watchOnChats,
  addMember,
  removeMember,
  setPageByChatId,
};
