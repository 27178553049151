import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { HistoryTypes } from "../../store";

import useDebounce from "../../utils/useDebounce";

import { ContainerUsers } from "./container";

import { Container } from "./styles";

import { Button } from "../../components/Button";

import ScrollToBottom from "react-scroll-to-bottom";

// types
import { Contact } from "../../store/modules/chats/types";
// components
import { Default } from "../../components/Layout";
import { Title, Email } from "../../components/Fonts";
import Avatar from "../../components/Avatar";
import Loading from "../../components/Loading";
import Card from "../../components/Card";

import check from "../../assets/icons/check.svg";
import { Text } from "../../components/Input";

type StatusValues = "disabled" | "enabled" | "all"
interface Props {
  payload: {
    data: {
      contacts: Contact[];
      isLoading: boolean;
      paginate: any;
      status: string;
      setStatus: (status: StatusValues) => void;
    };
    actions: {
      searchMember(data): void;
      enableMember(Array);
      disableMember(Array);
      handleChangeStatus(data): void;
    };
  };
}

const Users: React.FC<Props> = ({ payload }) => {
  const { searchMember, enableMember, disableMember, handleChangeStatus } = payload.actions;
  const { contacts, isLoading, paginate, status, setStatus } = payload.data;

  const history = useHistory<HistoryTypes>();

  const [contactsIsNotAdmin, setContactsIsNotAdmin] = useState<Contact[]>([]);
  const [memberName, setMemberName] = useState("");
  const [noUsersTitle, setNoUsersTitle] = useState("");

  const debounceChange = useDebounce(searchMember);

  const noUsersStatus = {
    all: "Nenhum usuário encontrado",
    disabled: "Não há usuários para serem ativados",
    enabled: "Não há usuários para serem desativados",
  };

  useEffect(() => {
    setContactsIsNotAdmin(() =>
      contacts.filter((item) => item.isAdmin === false).sort((a, b) => (a.name > b.name ? 1 : -1)),
    );
  }, [contacts]);

  useEffect(() => {
    if (memberName || status) {
      debounceChange({ name: memberName, status });
    }
    setNoUsersTitle(() => noUsersStatus[status]);
  }, [memberName, status, isLoading]);

  const handleMembersIds = (id: string) => {
    const contact = contactsIsNotAdmin.find((contact) => contact.id === id);

    if (contact) {
      const newStatus = contact?.status === "enabled" ? "disabled" : "enabled";
      contact.status = newStatus;
      setContactsIsNotAdmin((current) => [...current]);
    }
  };

  useEffect(() => {
    setStatus(history.location.state?.status ?? "all");
  }, [history.location]);

  const handleEnableMember = () => {
    const enableMembersIds = contactsIsNotAdmin.filter((item) => item.status == "enabled").map((item) => item.id);
    const disableMembersIds = contactsIsNotAdmin.filter((item) => item.status == "disabled").map((item) => item.id);
    
    enableMember(enableMembersIds);
    disableMember(disableMembersIds);
    history.push("/", { filter: "all" })

  };

  const handleMemberName = (event) => {
    setMemberName(event.target.value);
  };

  const nextPage = () => {
    if (paginate.currentPage < paginate.lastPage) {
      searchMember({ name: memberName, status, page: paginate.currentPage + 1, keep: true });
    }
  };
  return (
    <Default>
      <Container>
        <div className="search-user">
          <Text
            name="memberName"
            onChange={handleMemberName}
            value={memberName}
            placeholder="Pesquise um integrante"
          />
        </div>
        {!contacts.length ? (
          <div className="no-users">
            <Title fullWidth isArchived>{noUsersTitle}</Title>
          </div>
        ) : (
          <section className="list-users">
            {isLoading && <Loading />}
            {!isLoading &&
              contactsIsNotAdmin.length !== 0 &&
              contactsIsNotAdmin.map((item) => (
                <Card key={item.id}>
                  <Avatar name={item.name} />
                  <section className="profile-data"> 
                    <Title title={item.name}>{item.name}</Title>
                    <Email>{item.email}</Email>
                  </section>
                  <button
                    className={
                      item.status === "enabled" ? "btn-toogle-status remove" : "btn-toogle-status"
                    }
                    onClick={() => handleMembersIds(item.id)}
                  >
                    {item.status === "enabled" && <img src={check} alt="Check" />}
                  </button>
                </Card>
              ))}
            <ScrollToBottom mode="bottom" className="scrollcp">
              {paginate.currentPage < paginate.lastPage && (
                <p className="load-more" onClick={nextPage}>
                  Carregar mais
                </p>
              )}
            </ScrollToBottom>
          </section>
        )}
        <div className="wrapper-btn">
          <Button type="button" onClick={handleEnableMember}>Salvar</Button>
        </div>
      </Container>
    </Default>
  );
};

export default ContainerUsers(Users);
