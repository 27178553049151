import React, { createContext, useContext, useEffect } from "react";

import { useSelector } from "react-redux";

import { ApplicationState } from "../store";

import { unreadAllUserAuthenticated, stateChats } from "../store/modules/chats/selectors";
import { userIdAuthenticated } from "../store/modules/auth/selectors";

interface NotificationContext { }

const NotificationContext = createContext({} as NotificationContext);

export const NotificationContextProvider: React.FC = ({ children }) => {
  const userAuthId = useSelector(userIdAuthenticated);

  const chats = useSelector(stateChats);

  const unread = useSelector((state: ApplicationState) => unreadAllUserAuthenticated(state, userAuthId));

  useEffect(() => {
    window.parent.postMessage({ unread: unread }, "*");
  }, [chats]);

  return <NotificationContext.Provider value={{}}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  return context;
};
