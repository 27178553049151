import styled from "styled-components";

interface Props {
    error?: boolean;
    fullWidth?: boolean;
    center?: boolean;
    mt?: "1" | "2" | "3" | "4" | "5";
    mb?: "1" | "2" | "3" | "4" | "5";
    width?: string;
    isArchived?: boolean;
    fontWeight?: string;
    fontSize?: string;
    lineHeight?: string;
    textAlign?: string;
}

const sizes = {
    "1": "10px",
    "2": "15px",
    "3": "20px",
    "4": "25px",
    "5": "30px",
};

export const Title = styled.p<Props>`
    font-weight: ${(props) => props.fontWeight || "500"};
    font-size: ${(props) => props.fontSize || "18px"};
    line-height: ${(props) => props.lineHeight || "1.2em"};
    text-align: ${(props) => props.textAlign || "left"};
    color: ${(props) => (props.isArchived ? "#A6A6A6" : "#000")};

    max-width: ${(props) => (!props.fullWidth ? "20ch" : "100vw")};
    overflow: ${(props) => (!props.fullWidth ? "hidden " : "")};
    text-overflow: ${(props) => (!props.fullWidth ? "ellipsis " : "")};
    white-space: ${(props) => (!props.fullWidth ? "nowrap " : "")};
`;

export const Subtitle = styled.p<Props>`
    font-weight: ${(props) => props.fontWeight || "300"};
    font-size: ${(props) => props.fontSize || "13px"};
    line-height: ${(props) => props.lineHeight || "1.2em"};
    text-align: ${(props) => props.textAlign || "left"};
    color: ${(props) => (props.isArchived ? "#A6A6A6" : props.error ? "#ff4370" : "#000")};
    max-width: ${(props) => (props.fullWidth ? "100vw" : "30ch")};
    overflow: ${(props) => (!props.fullWidth ? "hidden " : "")};
    text-overflow: ${(props) => (!props.fullWidth ? "ellipsis " : "")};
`;

export const Email = styled.p<Props>`
    font-weight: 300;
    font-size: 13px;
    line-height: 1.2em;
    color: ${(props) => (props.error ? "#ff4370" : "#000")};

    text-align: ${(props) => (props.center ? "center" : "unset")};
    margin-top: ${(props) => sizes[props.mt || ""] || "unset"};
    margin-bottom: ${(props) => sizes[props.mb || ""] || "unset"};
    width: ${(props) => props?.width || "unset"};

    max-width: ${(props) => (!props.fullWidth ? "20ch " : "")};
    overflow: ${(props) => (!props.fullWidth ? "hidden " : "")};
    text-overflow: ${(props) => (!props.fullWidth ? "ellipsis " : "")};
    white-space: ${(props) => (!props.fullWidth ? "nowrap " : "")};
`;

export const TextOverflow = styled.p`
    font-weight: 300;
    font-size: 13px;
    line-height: 1.2em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    width: 100px;
    flex: 1;

    color: #000;
`;

export const Text = styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
`;
