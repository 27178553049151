import React from "react";
import { Message } from "../../../store/modules/messages/types";
import { Title } from "../../../components/Fonts";
import { Container, Wrapper, DateMessage } from "./styles";

import formatDate from "../../../utils/formatDate";

import TextContent from "./TextContent";
import Attachment from "../../../components/Attachment";

interface Props extends Message {
    isFromMe: boolean;
    me: string;
    isGroup: boolean;
}

const Contect: React.FC<Props> = ({ isFromMe, me, isGroup, ...rest }) => {
    const handleTypeMessage = (message) => {
        if (rest?.file?.url) {
            return <Attachment 
                src={rest.file.url} 
                mimetype={rest.file.mimetype} 
                filename={rest.file.filename} 
                left={isFromMe}
                />;
        }

        return <TextContent text={message.text} />;
    };

    return (
        <>
            <Wrapper left={isFromMe}>
                <Container left={isFromMe}>
                    <Title isArchived={rest?.contact.status !== "disabled" ? false : true} fontSize="2">
                        {rest.contactId != me && isGroup && rest?.contact?.name}
                    </Title>
                    {handleTypeMessage(rest)}
                    <DateMessage left={isFromMe}>{formatDate(new Date(rest.createdAt), "HH:mm")}</DateMessage>
                </Container>
            </Wrapper>
        </>
    );
};

export default Contect;
