import api from "../../service/api";

const mapContact = {
    fromWorkspace: () => api.get("/contacts/from-workspace").then((response) => response),
    searchContact: (query) => api.get("/contacts", { params: query }).then((response) => response.data),
    enableMember: (payload) => api.post("/contacts/enable-contact", payload).then((response) => response.data),
    disableMember: (payload) => api.post("/contacts/disable-contact", payload).then((response) => response.data),
    searchAllContact: (query) => api.get("/contacts/all", { params: query }).then((response) => response.data)
};

export { mapContact };
