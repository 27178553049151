import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ContainerHome } from "./container";
import useDebounce from "../../utils/useDebounce";
import { HistoryTypes } from "../../store";
import { userAuthenticated } from "../../store/modules/auth/selectors";
import { Container } from "./styles";

// types
import { Chat, Contact } from "../../store/modules/chats/types";

// components
import { Default } from "../../components/Layout";
import { Title, Subtitle, TextOverflow } from "../../components/Fonts";
import Card from "../../components/Card";

import Avatar from "../../components/Avatar";
import { Button, IconButton } from "../../components/Button";
import AvatarGroup from "../../components/AvatarGroup";
import Badge from "../../components/Badge";
import Unread from "../../components/Unread";
import NoChats from "../../assets/icons/woman-no-chats.svg";
import IconPlus from "../../assets/icons/plus.svg";
import formatDate from "../../utils/formatDate";

interface Props {
  payload: {
    data: {
      chats: Chat[];
    };
    actions: {
      setCurrentContact(data): void;
      unread(chat, userId): number;
      searchChats(data): void;
    };
  };
}

const Home: React.FC<Props> = ({ payload }) => {
  const { chats } = payload.data;
  const { setCurrentContact, unread, searchChats } = payload.actions;

  const user = useSelector(userAuthenticated);

  const [chatsFilterName, setChatsFilterName] = useState("");

  const debounceChange = useDebounce(searchChats);

  const history = useHistory<HistoryTypes>();

  useEffect(() => {
    if (history.location.state?.search) {
      setChatsFilterName(history.location.state.search);
    }
    if (chatsFilterName) {
      debounceChange(history.location.state);
      return;
    }
    searchChats(history.location.state);
  }, [history.location]);

  const deletedUser = "Usuário arquivado";

  const handleOtherContactName = (contacts: Contact[], getName = false) => {
    const contact = contacts.find((contact) => contact.id !== user.id && contact.status === "enabled");

    if (getName) return contacts.find((contact) => contact.id !== user.id)?.name;

    if (!contact) return deletedUser;

    return contact.name;
  };

  const openNewChat = !chats.length && history.location.state?.filter === "all";

  const isArchived = history.location.state?.filter === "archived";

  const userCanAddGroups =
    user.isAdmin ||
    chats.some((chat) => chat?.contacts.some((contact) => contact.id === user.id && contact.informations.isAdmin));

  const handleNewChat = () => {
    if (history.location.state?.filter === "groups" && userCanAddGroups) {
      history.push("/groups/create");
      return;
    }
    history.push("/new-chat");
  };

  return (
    <Default>
      <Container>
        <div className="wrapper-content">
          <div className="content">
            {!chats.length && (
              <div className="no-chats">
                <div className="wrapper-body">
                  <div className="wrapper-icon">
                    <img src={NoChats}></img>
                  </div>

                  <div className="wrapper-title">
                    <Title fullWidth isArchived>
                      Nenhuma conversa encontrada
                    </Title>
                  </div>
                </div>
                {openNewChat && !isArchived && (
                  <div className="wrapper-btn">
                    <Button type="button" onClick={handleNewChat}>
                      Nova Conversa
                    </Button>
                  </div>
                )}
              </div>
            )}

            {(chats || []).map((item, index) => (
              <Card key={item.id} onClick={() => setCurrentContact(item)}>
                {item.isGroup ? (
                  <AvatarGroup members={item?.contacts.filter((contact) => contact.status === "enabled")} />
                ) : (
                  <Avatar name={handleOtherContactName(item.contacts, true)} />
                )}

                <div className="profile-data">
                  <div className="wrapper-badge">
                    <Title
                      fontSize="16px"
                      style={{ marginBottom: "4px" }}
                      isArchived={
                        item.isGroup ? false : handleOtherContactName(item.contacts, false) === "Usuário arquivado"
                      }
                      title={item.isGroup ? item.name : handleOtherContactName(item.contacts, false)}
                    >
                      {item.isGroup ? item.name : handleOtherContactName(item.contacts, true)}
                    </Title>
                    {item.isGroup && <Badge title={item?.workspace?.name}>{item?.workspace?.name}</Badge>}
                  </div>
                  {!item.isGroup && (
                    <div className="wrapper-message">
                      {!item.lastMessage && <Subtitle> Nenhuma mensagem encontrada </Subtitle>}
                      <TextOverflow>{item?.lastMessage?.file ? "Arquivo" : item?.lastMessage?.text}</TextOverflow>
                    </div>
                  )}
                </div>
                <div className="info">
                  {item?.contacts?.length > 0 && unread(item, user.id) > 0 && <Unread>{unread(item, user.id)}</Unread>}
                  {item?.lastMessage?.createdAt &&
                    formatDate(new Date((item?.lastMessage?.createdAt).toString()), "HH:mm")}
                </div>
              </Card>
            ))}

            {!openNewChat && !isArchived && (
              <div className="wrapper-btn-new-chat" title="Nova Conversa">
                <IconButton icon={IconPlus} width="30px" height="30px" onClick={handleNewChat} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </Default>
  );
};

export default ContainerHome(Home);
