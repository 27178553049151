import styled from "styled-components";
interface Props {
    isArchived?: boolean;
}

export const Container = styled.p<Props>`
    height: 18px;
    width: auto;
    max-width: 80vw;

    padding: 0px 8px;
    background: ${(props) => (props.isArchived ? "#A6A6A6" : "#52658c")};

    border-radius: 15px;

    color: #fff;
    font-size: 12px;
    font-weight: 500;
    title: rigth;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
