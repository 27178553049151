import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 66px);

  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .wrapper-content {
      display: flex;
      height: 100%;
      width: 100% ;
      align-items: center;
      justify-content: center;
      flex-direction: column ;
      overflow-y: auto;
  }

  .wrapper-no-channels {
      display: flex;
      height: 100%;
      width: 100% ;
      align-items: center;
      justify-content: center;
      flex-direction: column ;
    }

    .wrapper-body {
      display: flex;
      height: 100%;
      width: 100% ;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column ;
    }

    .wrapper-icon {
      padding: 16px ;
      display: flex;
      width: 100% ;
      align-items: center;
      justify-content: center;
    }

    .wrapper-title {
      padding: 16px ;
      display: flex;
      width: 100% ;
      align-items: center;
      justify-content: center;
    }

    div .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    div .wrapper .title {
      margin-left: 7px;
      display: flex;
      flex-direction: column;
    }
  

  .wrapper-btn {
    padding: 16px;
    margin-top: 20px ;
    width: 100%;
  }
`;
