import React from "react";

import { Container, Item } from "./styles";

import { Contact } from "../../store/modules/contacts/types";

interface Props {
  members: Contact[];
}

const AvatarGroup: React.FC<Props> = ({ members }) => {
  if (members.length > 3) {
    return (
      <Container>
        {members.slice(0, 3).map((item) => (
          <Item> {item.name.slice(0, 1)} </Item>
        ))}
        <Item latest> {members.length > 12 ? "+9" : members.length - 3} </Item>
      </Container>
    );
  }

  return (
    <Container>
      {members.map((item) => (
        <Item>{item.name.slice(0, 1)}</Item>
      ))}
    </Container>
  );
};

export default AvatarGroup;
