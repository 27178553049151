import React from "react";

import { Container } from "./styles";

interface Props {
    fullHeight?: boolean;
}

const CardScroll: React.FC<Props> = ({ children, fullHeight }) => {
    return <Container> {children} </Container>;
};

export default CardScroll;
