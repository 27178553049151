import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { actions } from "../../store/modules/workspaces/actions";

// selectors
import { data, getPage } from "../../store/modules/workspaces/selectors";

export const ContainerClass = (Component) => {
  const Container = () => {
    useEffect(() => {
      handleLoad();
    }, []);

    const dispatch = useDispatch();

    const workspaces = useSelector(data);

    const workspacePage = useSelector(getPage);

    const handleLoad = () => {
      dispatch(actions.load());
    };

    const handleDestroy = (id) => {
      dispatch(actions.destroy({ id }));
    };

    const handleNextPage = () => {
      const { currentPage, lastPage } = workspacePage;
      if (currentPage === lastPage) return;
      const nextPage = currentPage + 1;
      dispatch(actions.load({ page: nextPage }));
    };

    const hasNextPage = workspacePage?.currentPage < workspacePage?.lastPage;

    return (
      <Component
        payload={{
          data: {
            hasNextPage,
            workspaces,
          },
          actions: {
            destroy: handleDestroy,
            handleNextPage,
          },
        }}
      />
    );
  };

  return Container;
};
