import React from "react";
import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { ContainerGroups } from "./container";

import { Container } from "./styles";

import { Chats } from "../../store/modules/workspaces/types";
import { userAuthenticated } from "../../store/modules/auth/selectors";

// components
import { Default } from "../../components/Layout";
import { Subtitle, Title } from "../../components/Fonts";
import Avatar from "../../components/Avatar";
import Dropdown from "../../components/Dropdown";
import Card from "../../components/Card";
import { Button } from "../../components/Button";
import Grupos from "../../assets/icons/no-services.svg";

interface Props {
  payload: {
    data: {
      chats: Chats[];
      workspaces: boolean;
    };
    actions: {
      destroy(data): void;
    };
  };
}

const Groups: React.FC<Props> = ({ payload }) => {
  const { chats, workspaces } = payload.data;
  const { destroy } = payload.actions;

  const user = useSelector(userAuthenticated);

  const history = useHistory();

  const userChats = user.isAdmin
    ? chats
    : chats.filter((chat) => chat.contacts.some((contact) => !!contact.informations.isAdmin && contact.id === user.id));

  return (
    <Default>
      <Container>
      <div className="wrapper-content">
          {!userChats.length ? (
            <div className="wrapper-no-groups">
              <div className="wrapper-icon">
                <img src={Grupos}></img>
              </div>
              <div className="wrapper-title">
                <Title fullWidth isArchived>
                  Não existem grupos criados
                </Title>
              </div>
            </div>
          ) : (
            <div className="wrapper-body">
              {userChats?.map((item) => (
                <Card border={false} key={item.id}>
                  <Avatar name={item.name} />
                  <div className="wrapper">
                    <div className="title">
                      <Title title={item.name}>{item.name}</Title>
                      <Subtitle>
                        {" "}
                        {item.contacts.filter((contact) => contact.status !== "disabled").length > 1
                          ? `${item.contacts.filter((contact) => contact.status !== "disabled").length} integrantes`
                          : `${item.contacts.filter((contact) => contact.status !== "disabled").length} integrante`}
                      </Subtitle>
                    </div>
                    <Dropdown
                      items={[
                        {
                          label: "Editar",
                          handleClick: () => history.push(`/groups/${item.id}/edit`),
                        },
                        {
                          label: "Excluir",
                          handleClick: () => destroy(item.id),
                        },
                      ]}
                    ></Dropdown>
                  </div>
                </Card>
              ))}
            </div>
          )}
          </div>
        {user.isAdmin && (
          <div className="wrapper-btn">
            <Button
              onClick={() =>
                !workspaces ? history.push("/class/create", { noGroups: true }) : history.push("/groups/create")
              }
            >
              {" "}
              Criar grupo{" "}
            </Button>
          </div>
        )}
      </Container>
    </Default>
  );
};

export default ContainerGroups(Groups);
