import { put, call, take } from "redux-saga/effects";

import audio from "../../../assets/audio/audio.mp3";

import { types } from "./types";
import { actions as actionsChats } from "../chats/actions";

import api from "../../../service/api";

import { createSocketConnection, createSocketChannel } from "../../../service/socket";

function* load(action) {
    const { payload } = action;

    const { chatId } = payload;

    try {
        const response = yield call(api.get, `/messages/from-chat/${chatId}`, {
            params: {
                include: "contact",
                order: [["createdAt", "DESC"]],
                page: payload.page || 1,
            },
        });

        const dataPaginated = {
            id: chatId,
            currentPage: response.data.currentPage,
            from: response.data.from,
            lastPage: response.data.lastPage,
            limit: response.data.limit,
            skip: response.data.skip,
            to: response.data.to,
            total: response.data.total,
        };

        yield put(actionsChats.setPage(dataPaginated));

        yield put({ type: types.MESSAGES_LOAD_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: types.MESSAGES_LOAD_FAILURE });
    }
}

function* loadMore(action) {
    const { payload } = action;

    const chatId = payload.id;
    const page = payload.page;

    try {
        const response = yield call(api.get, `messages/from-chat/${chatId}`, {
            params: {
                include: "contact",
                order: [["createdAt", "DESC"]],
                page,
            },
        });

        const dataPaginated = {
            id: chatId,
            currentPage: response.data.currentPage,
            from: response.data.from,
            lastPage: response.data.lastPage,
            limit: response.data.limit,
            skip: response.data.skip,
            to: response.data.to,
            total: response.data.total,
        };

        yield put(actionsChats.setPage(dataPaginated));

        yield put({ type: types.MESSAGES_LOAD_MORE_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: types.MESSAGES_LOAD_MORE_FAILURE });
    }
}

function* create(action) {
    const { type, payload } = action;

    const { chatId, handleEnableLoading, handleDisableLoading } = payload;

    handleEnableLoading();

    const payloadData = new FormData();

    payload.text && payloadData.append("text", payload.text);
    payload.file && payloadData.append("file", payload.file);

    try {
        const response = yield call(api.post, `/messages/send/${chatId}`, payloadData, {
            params: {
                include: "contact",
            },
        });

        yield put({ type: types.MESSAGES_CREATE_SUCCESS, payload: response.data });
        handleDisableLoading();
    } catch (error) {
        yield put({ type: types.MESSAGES_CREATE_FAILURE });
    }
}

function* update(action) {
    const { type, payload } = action;

    try {
        // const response = yield call(api.post, "/", payload);

        yield put({ type: types.MESSAGES_UPDATED_SUCCESS });
    } catch (error) {
        yield put({ type: types.MESSAGES_UPDATED_FAILURE });
    }
}

function* destroy(action) {
    const { type, payload } = action;

    try {
        // const response = yield call(api.post, "/", payload);

        yield put({ type: types.MESSAGES_DESTROY_SUCCESS });
    } catch (error) {
        yield put({ type: types.MESSAGES_DESTROY_FAILURE });
    }
}

function* watchOnMessages() {
    const socket = yield call(createSocketConnection);
    const socketChannel = yield call(createSocketChannel, socket, "messages");

    while (true) {
        try {
            const payload = yield take(socketChannel);

            yield put({ type: types.MESSAGES_ON_SOCKET, payload });
        } catch (err) {
            console.log("[error-socket]::", JSON.stringify(err, null, 4));
        }
    }
}

function* notificationSaga(action) {
    const { payload } = action;

    new Audio(audio).play();

    if (Notification.permission === "granted") {
        const title = payload.chat.isGroup ? payload.chat.name : payload.contact.name;

        new Notification(title, {
            body: payload.file ? "Arquivo" : payload.text,
        });
    }
}

export default {
    load,
    create,
    update,
    destroy,
    watchOnMessages,
    notificationSaga,
    loadMore,
};
