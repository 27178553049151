import React, { createContext, useContext, useState } from "react";

interface OnboardContextProps {
    serverName: string;
    handleChangeServerName(data): void;
}

const OnboardContext = createContext({} as OnboardContextProps);

export const OnboardContextProvider: React.FC = ({ children }) => {
    const [serverName, setServerName] = useState("");

    const handleChangeServerName = (name) => {
        setServerName(name);
    };

    return (
        <OnboardContext.Provider
            value={{
                serverName,
                handleChangeServerName,
            }}
        >
            {children}
        </OnboardContext.Provider>
    );
};

export const useOnboardContext = () => {
    const context = useContext(OnboardContext);

    return context;
};
