import { BroadcastChannel } from "broadcast-channel";

import history from "../utils/history";

const logoutChannel = new BroadcastChannel("logout");

export const loggoutAllTabsEventListener = () => {
    logoutChannel.onmessage = (event) => {
        history.push("/login");

        logoutChannel.close();
    };
};

export const postmessageChannel = () => {
    logoutChannel.postMessage({ logoutMessage: "Oh! I am logged out." });
};
