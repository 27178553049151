import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// actions
import { actions } from "../../../store/modules/chats/actions";
import { actions as actionsWorkspaces } from "../../../store/modules/workspaces/actions";

// selectors
import { userIdAuthenticated } from "../../../store/modules/auth/selectors";
import { data } from "../../../store/modules/workspaces/selectors";
import { dataChats, getDetailsChatById } from "../../../store/modules/chats/selectors";

// hooks
import { useAsync } from "../../../hooks/useAsync";

// resource
import { mapContact } from "../../../resources/contacts/api";
import { ApplicationState } from "../../../store";

export const ContainerGroups = (Component) => {
    const Container = () => {
        const dispatch = useDispatch();

        const { id } = useParams();
        const isEditing = !!id;

        const [dataSearch, setDataSearch] = useState([]);

        const [disable, setDisable] = useState(false);

        const { execute, status, value, error } = useAsync(mapContact.searchContact);
        const {
            execute: executeSearchAllContact,
            status: statussearchAllContact,
            value: valueSearchAllContact,
            error: errorSarchAllContact,
        } = useAsync(mapContact.searchAllContact);

        const userId = useSelector(userIdAuthenticated);
        const chats = useSelector(dataChats);
        const workspaces = useSelector(data);
        const previousChat = isEditing ? useSelector((state: ApplicationState) => getDetailsChatById(state, id)) : null;

        useEffect(() => {
            handleLoadWorkspaces();
            handleSearchAllContact();
        }, []);

        useEffect(() => {
            if (value?.data?.length) {
                setDataSearch(value.data.filter((item) => item.id !== userId));
            }
        }, [value]);

        const handleLoadWorkspaces = () => {
            dispatch(actionsWorkspaces.load());
        };

        const handleSearchMember = async (name) => {
            const query = {
                where: {
                    $or: {
                        name: {
                            $like: `%${name}%`,
                        },
                        email: {
                            $like: `%${name}%`,
                        },
                    },                    
                    status: 'enabled',
                },
            };

            execute(query);
        };

        const handleSearchAllContact = async () => {
            executeSearchAllContact();
        };

        const handleSubmit = (values) => {            
            if (values.contactsIds.length < 1) {
                toast.warn("Insira ao menos 1 integrante!");
                setDisable(false)
                return;
            }
            dispatch(
                actions.create({
                    ...values,
                    isGroup: true,
                }),
            );
        };

        const handleUpdate = (data) => {
            if (data.contactsIds.length < 2) {
                toast.warn("Insira ao menos 1 integrante!");
                setDisable(false)
                return;
            }
            dispatch(actions.update({ ...data, id }));
        };

        return (
            <Component
                payload={{
                    data: {
                        workspaces,
                        chats,
                        contactsAdmin: valueSearchAllContact || [],
                        contacts: dataSearch,
                        isEditing,
                        previousChat,
                        disable, 
                    },
                    actions: {
                        submit: handleSubmit,
                        searchMember: handleSearchMember,
                        update: handleUpdate,
                        setDisable
                    },
                }}
            />
        );
    };

    return Container;
};
