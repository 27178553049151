import { put, call, take } from "redux-saga/effects";

import { types } from "./types";
import { actions as actionsContacts } from "../auth/actions";

import api from "../../../service/api";

import { createSocketConnection, createSocketChannel } from "../../../service/socket";

function* load() {
    try {
        const response = yield call(api.get, "/contacts/from-workspace");

        yield put({ type: types.CONTACTS_LOAD_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: types.CONTACTS_LOAD_FAILURE });
    }
}

function* show(action) {
    const { type, payload } = action;

    try {
        const response = yield call(api.get, `/contacts/${payload}`);

        yield put({ type: types.CONTACTS_SHOW_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: types.CONTACTS_SHOW_FAILURE });
    }
}

function* create(action) {
    const { type, payload } = action;

    try {
        // const response = yield call(api.post, "/", payload);

        yield put({ type: types.CONTACTS_CREATE_SUCCESS });
    } catch (error) {
        yield put({ type: types.CONTACTS_CREATE_FAILURE });
    }
}

function* update(action) {
    const { type, payload } = action;

    const contactId = payload.id;
    const data = payload.payload;

    try {
        const response = yield call(api.put, `/contacts/${contactId}`, data);

        yield put(actionsContacts.me());

        yield put({ type: types.CONTACTS_UPDATED_SUCCESS });
    } catch (error) {
        yield put({ type: types.CONTACTS_UPDATED_FAILURE });
    }
}

function* destroy(action) {
    const { type, payload } = action;

    try {
        // const response = yield call(api.post, "/", payload);

        yield put({ type: types.CONTACTS_DESTROY_SUCCESS });
    } catch (error) {
        yield put({ type: types.CONTACTS_DESTROY_FAILURE });
    }
}

function* watchOnContacts() {
    const socket = yield call(createSocketConnection);
    const socketChannel = yield call(createSocketChannel, socket, "contacts");

    while (true) {
        try {
            const payload = yield take(socketChannel);

            yield put({ type: types.CONTACTS_ON_SOCKET, payload });
        } catch (err) {
            console.log("[error-socket]::", JSON.stringify(err, null, 4));
        }
    }
}

function* enableMember() {
    console.log("function enable member");
}

function* loadMemberEnables() {
    try {
        const response = yield call(api.get, "/contacts/from-workspace");

        yield put({ type: types.CONTACTS_LOAD_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: types.CONTACTS_LOAD_FAILURE });
    }
}

export default {
    load,
    show,
    create,
    update,
    destroy,
    watchOnContacts,
    enableMember,
    loadMemberEnables,
};
