import styled from "styled-components";

interface Props {
    left: boolean;
}

export const Container = styled.div`
    width: 100%;
    max-width: 350px;
    max-height: 350px;

    transition: 0.2s;

    :hover {
        opacity: 0.6;
    }
`;

export const Image = styled.img`
    width: 100%;
    max-width: 350px;
    max-height: 350px;
`;

export const Player = styled.audio`
    width: 250px;
    height: 35px;
`;

export const Link = styled.a<Props>`
    color: ${(props) => (props.left ? "#171515" : "#171515")};

    :hover {
        color: ${(props) => (props.left ? "#171515" : "#171515")};
    }
`;
