import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

// actions
import { actions } from "../../store/modules/contacts/actions";

// selectors

// hooks
import { useAsync } from "../../hooks/useAsync";

// resource
import { mapContact } from "../../resources/contacts/api";
import { Contact } from "../../store/modules/chats/types";

export const ContainerUsers = (Component) => {
  const Container = () => {
    const dispatch = useDispatch();

    const { execute, value } = useAsync(mapContact.searchContact);
    const { execute: executeEnable, value: valueEnable, status: enableStatus } = useAsync(mapContact.enableMember);
    const { execute: executeDisable, value: valueDisable, status: disableStatus } = useAsync(
      mapContact.disableMember,
    );

    const [data, setData] = useState<Contact[]>([]);
    const [status, setStatus] = useState<"disabled" | "enabled" | "all">("all");
    const [paginate, setPaginate] = useState({});

    useEffect(() => {
      setData(data.filter((item) => item.status === "enabled"));
    }, [valueEnable]);

    useEffect(() => {
      setData(data.filter((item) => item.status === "disabled"));
    }, [valueDisable]);

    useEffect(() => {
      if (value?.data) {
        setData([...data, ...value?.data]);
        setPaginate({
          currentPage: value.currentPage,
          lastPage: value.lastPage,
        });
      }
    }, [value]);

    useEffect(() => {
      dispatch(actions.loadMembersEnable());
    }, []);

    const handleSearchMember = (data) => {
      const { name, status, page = 1, keep = false } = data;
      if (!keep) {
        setData([]);
      }
      if (status != "all") {
        const query = {
          page: page,
          where: {
            status: status,
            ...(status === "enabled" && { isAdmin: false }),
            ...(name && {
              $or: {
                name: {
                  $like: `%${name}%`,
                },
                email: {
                  $like: `%${name}%`,
                },
              },
            }),
          },
          order: [["name", "ASC"]],
        };
        execute(query);
        return;
      }

      const query = {
        page: page,
        where: {
          isAdmin: false,
          $or: {
            name: {
              $like: `%${name}%`,
            },
            email: {
              $like: `%${name}%`,
            },
          },
        },
        order: [["name", "ASC"]],
      };
      execute(query);
    };

    const handleDisableMember = (ids) => {
      ids.map((id) => executeDisable({ contactId: id }));
    };

    const handleEnableMember = (ids) => {
      ids.map((id) => executeEnable({ contactId: id }));
    };

    const handleChangeStatus = (value) => {
      setStatus(value);
    };

    return (
      <Component
        payload={{
          data: {
            contacts: data,
            isLoading: enableStatus === "pending" || disableStatus === "pending",
            paginate,
            status,
            setStatus,
          },
          actions: {
            searchMember: handleSearchMember,
            enableMember: handleEnableMember,
            disableMember: handleDisableMember,
            handleChangeStatus,
          },
        }}
      />
    );
  };

  return Container;
};
