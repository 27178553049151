import styled from "styled-components";

export const Container = styled.div`

display: flex;
align-items: flex-start;
justify-content: center;
flex-direction: column;
position: relative;
    
    font-size: 16px;
    width: 100%;
    
    > label {
        font-weight: 400;
        margin-left: 17px;
    }
    
    > select {
        color: hsl(0,0%,50%);
        border: 1px solid rgba(82, 101, 140, 0.15);
        border-radius: 20px;
        width: 100%;
        background: transparent;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        padding: 8px 48px 8px 16px;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        :disabled {
            cursor: not-allowed;
        }

        > option {
            width: 100%;
        }
    }

    > img {
        width: 17px;
        filter: invert(49%) sepia(72%) saturate(7373%) hue-rotate(177deg) brightness(102%) contrast(86%);
        
        position: absolute;
        right: 37px;
        bottom: 18px;
        transform: translate(50%, 50%);

        z-index: -999999999999999;

        cursor: pointer;
    }
`;
