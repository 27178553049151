import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { HistoryTypes } from "../../store";

import { userAuthenticated } from "../../store/modules/auth/selectors";
import { dataChatsGroups } from "../../store/modules/chats/selectors";

import { useAsync } from "../../hooks/useAsync";
import webhooksResource from "../../resources/webhooks/api";

import { Title } from "../Fonts";
import Dropdown from "../Dropdown";
import { IconButton } from "../Button";
import { Text } from "../../components/Input";
import { Container } from "./styles";

import IconFilter from "./../../assets/icons/filter.svg";
import IconSearch from "./../../assets/new-icons/search.svg";
import group from "../../assets/icons/actions/create-group.svg";
import createChannel from "../../assets/icons/actions/create-channel.svg";
import syncUsers from "../../assets/icons/sync.png";
import activeContact from "../../assets/icons/actions/active-contact.svg";
import chat from "../../assets/icons/chat.svg";
import leftIcon from "../../assets/icons/chevron-left.svg";
import inactiveUsers from "../../assets/icons/users-inactive.svg";
import inactiveUser from "../../assets/icons/user-inactive.svg";
import allUsers from "../../assets/icons/users-all.svg";
import activeUser from "../../assets/icons/user-active.svg";

const Header: React.FC = (props) => {
  const [title, setTitle] = useState("");
  const [isArchived, setIsArchived] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { execute } = useAsync(webhooksResource.resyncContact);

  const history = useHistory<HistoryTypes>();

  const routesTitle = {
    "/": "Chat Interno",
    "/new-chat": "Nova Conversa",
    "/users": "Ativação de contatos",
    "/groups": "Grupos",
    "/groups/create": "Criar grupo",
    "/class": "Canais",
    "/class/create": "Criar canal",
  };

  useEffect(() => {
    setTitle(() => routesTitle[history.location.pathname] || "Voltar");
    setCurrentRoute(history.location.pathname);
  }, [history.location.pathname]);

  const user = useSelector(userAuthenticated);

  const handleHistory = (path: string) => {
    history.push(path, { filter: "all" });
  };

  const handleSetParamsToRoute = (status: "disabled" | "enabled" | "all") => {
    history.push("/users", { status: status, isFirstAccess: history.location?.state?.isFirstAccess });
  };

  const handleChangeSearch = (value) => {
    if (history.location.pathname == "/") {
      setSearchValue(value);
      history.push("/", {
        filter: history.location.state?.filter || "all",
        search: value,
      });
      return;
    }

    setSearchValue(value);

    history.push("/new-chat", {
      search: value,
    });
  };

  const itemsFilterChat = [
    {
      label: "Todos",
      icon: chat,
      handleClick: () => history.push("/", { filter: "all" }),
    },
    {
      label: isArchived ? "Arquivados" : "Ativos",
      icon: isArchived ? inactiveUser : activeUser,
      handleClick: () => {
        setIsArchived(!isArchived), history.push("/", { filter: isArchived ? "archived" : "active" });
      },
    },
    {
      label: "Grupos",
      icon: group,
      handleClick: () => history.push("/", { filter: "groups" }),
    },
    {
      label: "Conversas",
      icon: chat,
      handleClick: () => history.push("/", { filter: "privateChat" }),
    },
  ];

  const itemsFilterUsers = [
    {
      label: "Ativos",
      icon: group,
      handleClick: () => handleSetParamsToRoute("enabled"),
    },
    {
      label: "Inativos",
      icon: inactiveUsers,
      handleClick: () => handleSetParamsToRoute("disabled"),
    },
    {
      label: "Todos",
      icon: allUsers,
      handleClick: () => handleSetParamsToRoute("all"),
    },
  ];

  const itemsActions = [
    {
      label: "Sincronizar usuários",
      icon: syncUsers,
      name: "sync_users",
      handleClick: () => execute({ user }),
    },
    {
      label: "Ativar Usuário",
      icon: activeContact,
      name: "active_user",
      handleClick: () => handleHistory("/users"),
    },
    {
      label: "Canais",
      icon: createChannel,
      name: "channels",
      handleClick: () => handleHistory("/class"),
    },
    {
      label: "Grupos",
      icon: group,
      name: "groups",
      handleClick: () => handleHistory("/groups"),
    },
  ];

  const chats = useSelector(dataChatsGroups) || [];

  const isGroupAdmin = chats.some((chat) =>
    chat?.contacts.some((contact) => contact.id === user.id && contact.informations.isAdmin),
  );

  const items = user.isAdmin ? itemsActions : itemsActions.filter((action) => action.name === "groups");

  return (
    <Container>
      {isSearch && (
        <Text
          name="memberName"
          onChange={(e) => handleChangeSearch(e.target.value)}
          value={searchValue}
          placeholder="Pesquisar"
        />
      )}
      {!isSearch && (
        <section className="title-section">
          {currentRoute !== "/" && !history.location?.state?.isFirstAccess && (
            <button onClick={() => handleHistory("/")}>
              <img src={leftIcon} />
            </button>
          )}
          <Title lineHeight="1.2em">{title}</Title>
        </section>
      )}
      <section className="options-section">
        {["/new-chat", "/"].includes(currentRoute) && (
          <IconButton
            icon={IconSearch}
            onClick={() => {
              setIsSearch(!isSearch);
            }}
            data-testid="button-find-chat"
          />
        )}

        {!isSearch && currentRoute === "/" && (
          <Dropdown label="Filtrar por" icon={IconFilter} items={itemsFilterChat} />
        )}

        {!isSearch && currentRoute === "/users" && (
          <Dropdown label="Filtrar por" icon={IconFilter} items={itemsFilterUsers} />
        )}

        {((user.isAdmin || isGroupAdmin) && items.length && !isSearch && currentRoute === "/" && (
          <Dropdown label="Ações" items={items} maxWidth />
        )) ||
          null}
      </section>
    </Container>
  );
};

export default Header;
