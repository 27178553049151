import React from "react";

import { ContainerChannels } from "./container";

// Styles
import { Container } from "./styles";

// Store
import { Workspaces } from "../../store/modules/workspaces/types";

// components
import { Default } from "../../components/Layout";
import { Title } from "../../components/Fonts";
import Avatar from "../../components/Avatar";
import { Button } from "../../components/Button";
import Card from "../../components/Card";
import Unread from "../../components/Unread";

interface Props {
  payload: {
    data: {
      allUnread: { [key: string]: number }
      hasNextPage: boolean;
      workspaces: Workspaces[];
      total: number
    };
    actions: {
      setCurrentWorkspace(data): void;
      nextPage(): void;
    };
  };
}

const Channels: React.FC<Props> = ({ payload }) => {
  const { allUnread, workspaces, hasNextPage, total } = payload.data;
  const { setCurrentWorkspace, nextPage } = payload.actions;

  return (
    <Default>
      <Container>
        <div className="wrapper-content">
          <div className="content">
            {!workspaces.length && (
              <div className="no-channels">
                <Title isArchived>Nenhum canal encontrado!</Title>
              </div>
            )}

            {workspaces.length && workspaces.map((item) => (
              <Card key={item.id} onClick={() => setCurrentWorkspace(item)}>
                <Avatar name={item.name} />
                <div className="profile-data">
                  <div className="wrapper-info">
                    <Title> {item.name} </Title>
                  </div>
                </div>

                {allUnread[item.id] > 0 && (
                  <Unread>{allUnread[item.id]}</Unread>
                )}
              </Card>
            ))}

            <div className="wrapper-btn">
              {hasNextPage && <Button onClick={nextPage}>Carregar mais</Button>}
            </div>
          </div>
        </div>
      </Container>
    </Default>
  );
};

export default ContainerChannels(Channels);
