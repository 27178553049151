import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

//types
import { Chat } from "../../store/modules/chats/types";

import { Container, WrapperInfoProfile, ContentInfoProfile, ToggleInfo } from "./styles";

// components
import Avatar from "../Avatar";
import { Title, Subtitle } from "../Fonts";
import ProfileInfo from "../../components/Profile/ProfileInfo";

// assets
import leftIcon from "../../assets/icons/chevron-left.svg";
import info from "../../assets/new-icons/info.svg";
import close from "../../assets/icons/close.svg";

import { userIdAuthenticated } from "../../store/modules/auth/selectors";

interface Props {
    currentChat: Chat;
}

const Profile: React.FC<Props> = ({ currentChat }) => {
    const history = useHistory();

    const [toggleInfoProfile, setToggleInfoProfile] = useState(false);

    const userId = useSelector(userIdAuthenticated);

    const deletedUser = "Usuário arquivado";

    const redirectToPath = (path) => {
        history.push(path);
    };

    const otherUserName = (getName = false) => {
        const { contacts = [] } = currentChat ?? {};

        const { name } = contacts.find((item) => (item.id !== userId && item.status === "enabled")) || {};

        if(getName) return (contacts.find((contact) => (contact.id !== userId)))?.name;


        if (!name) return deletedUser;

        return name;
    };

    const handleToggleInfoProfile = () => {
        setToggleInfoProfile(!toggleInfoProfile);
    };

    return (
        <Container>
            <div className="wrapper-profile">
                <button onClick={toggleInfoProfile ? () => handleToggleInfoProfile() : () => redirectToPath("/")}>
                    <img src={leftIcon} />
                </button>

                {!toggleInfoProfile && (
                    <Avatar size="sm" name={currentChat?.isGroup ? currentChat.name : otherUserName(true)} />
                )}
                {toggleInfoProfile ? (
                    <Title fullWidth>Informações do grupo</Title>
                ) : (
                    <section className="group-details">
                        <Title isArchived={currentChat?.isGroup ? false : otherUserName() === "Usuário arquivado"} 
                            title={currentChat?.isGroup ? currentChat.name : otherUserName(true)}
                        >
                            {currentChat?.isGroup ? currentChat.name : otherUserName(true)}
                        </Title>
                        {currentChat?.isGroup && (
                            <Subtitle>
                                {currentChat.contacts.filter((contact)=> contact.status === 'enabled').length > 1
                                    ? `Você + ${currentChat.contacts.filter((contact)=> contact.status === 'enabled').length - 1} integrante(s)`
                                    : `1 integrante`}
                            </Subtitle>
                        )}
                    </section>
                )}
            </div>
            {currentChat?.isGroup && (
                <ToggleInfo onClick={handleToggleInfoProfile}>
                    <img src={toggleInfoProfile ? close : info} />
                </ToggleInfo>
            )}
            <WrapperInfoProfile open={toggleInfoProfile}>
                <ContentInfoProfile open={toggleInfoProfile}>
                    <ProfileInfo handleToggleInfoProfile={handleToggleInfoProfile} />
                </ContentInfoProfile>
            </WrapperInfoProfile>
        </Container>
    );
};

export default Profile;
