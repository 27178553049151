import { DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";
import chevronDown from "../../assets/icons/chevrons-down.svg";

interface PropsDropzone extends DropzoneRootProps {
    isDragActive?: boolean;
    isMessage?: boolean;
}

export const Container = styled.div`
    width: 100%;
    height: calc(100vh - 66px);

    background: #ffffff;

    padding: 5px;

    form {
        width: 100%;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #E5E5E5;

        > textarea {
            font-size: 13px;
        }

        .icon-action {
                margin: 0 5px;
                transition: 0.2s;
                background: transparent;

                img {
                    width: 20px;
                    height: 20px;
                }

                :hover {
                    color: #000;
                }
            }

        .actions {
            display: flex;
            
            label {
                width: 25px;
                height: 25px;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                width: 25px;
                height: 25px;                
                color: #52658c;
                cursor: pointer;
            }

            .container-hidden {
                position: fixed;
                left: 0px;
                top: 0px;
                height: 100vh;
                width: 100%;
                z-index: 1;

                .emoji-container {
                    margin: 0% 2%;
                    width: 96%;
                    height: 50%;
                    position: absolute;
                    bottom: 55px;
                    overflow-y: hidden;
                    border: 1px solid #969696;
                    border-radius: 5px;
                }
                .emoji-mart-search-icon {
                    top: 2px;
                }
                svg {
                    width: 20px;
                    height: 20px;
                    color: #52658c;
                }     
                .emoji-mart-anchor-bar {
                    background-color: #52658c !important;

                }
            }
        }
    }
    .wrapper-no_messages {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        height: 100% ;
    }
`;

export const WrapperContent = styled.div<PropsDropzone>`
    width: 100%;
    height: ${(props) => (props.isMessage ? "calc(100% - 70px)" : "calc(100% - 50px)")};
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    border: ${(props) => (props.isDragActive ? "3px dashed green" : "")};

    .scrollcp {
      height: 100%; 
    }

    .button-to-scroll-bottom {
      background-color: #F5F5F5;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-image: url(${chevronDown});
      background-position-x: center;
      background-position-y: center;
      background-size: 20px;
    }
`;

export const Button = styled.button`
    width: 100%;
    height: 45px;
    background: #ffffff;
    color: #52658c;
    size: 10px;
    border-radius: 20px;
`;

export const LabelMessage = styled.div<{ danger?: boolean }>`
    color: ${(props) => (props.danger ? "red" : "#52658c")};
    text-align: center;
`;
