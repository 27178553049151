import React from "react";

import { Container } from "./styles";

//types
import { Chat } from "../../../store/modules/chats/types";


// components

import Profile from "../../Profile";
interface Props {
    currentChat: Chat;
}

const Message: React.FC<Props> = ({ children, currentChat }) => {
    return (
        <Container>
            <Profile currentChat={currentChat} />
            {children}
        </Container>
    );
};

export default Message;
