import React from "react";

import { Container } from "./styles";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Large: React.FC<Props> = ({ children, ...rest }) => {
    return <Container {...rest}> {children} </Container>;
};

export default Large;
