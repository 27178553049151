import styled from "styled-components";
interface Props {
  width?: string;
  height?: string;
}

export const Container = styled.button<Props>`
  width: 36px;
  height: 36px;

  background: #f6f6f6;
  border-radius: 50%;

  transition: all 0.2s;

  >img {
    width: ${(props) => (props.width ? props.width  : '15px')};
    height: ${(props) => (props.height ? props.height : '15px')};
  }

  :hover {
    filter: brightness(0.95);
  }
`;
