import React from "react";
import { Link } from "./styles";

interface Props {
    url: string;
    filename: string;
    left: boolean;
}

const MessageDocument: React.FC<Props> = ({ url, filename, left }) => {
    return (
        <Link href={url} download target="blank" left={left}>
            {filename}
        </Link>
    );
};

export default MessageDocument;
