import React from "react";

import { Container } from "./styles"

const Unauthorized: React.FC = () => {
  return (
    <Container>
      <span>Oops! :(</span>
      <h1>Entre no digisac para continuar a usufruir do Chat Interno.</h1>
    </Container>
  );
};

export default Unauthorized;
