import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;

  > .wrapper-content {
    width: 100%;
    height: calc(100vh - 66px);
  }

  > .wrapper-content .content {
    width: 100%;
    height: 100%;

    overflow-y: auto;

    span.unread {
      position: absolute;
      top: -7px;
      right: 5px;

      background: #5c9dfe;
      border: 2px solid #f5f9ff;
      border-radius: 50%;

      font-size: 8px;
      width: 15px;
      height: 15px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    > div .profile-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 7px;
      width: 100%;

      > .wrapper-badge {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }

      > .wrapper-message {
        display: flex;
        align-items: center;

        p:nth-child(2) {
          margin-left: 7px;
        }
      }
    }
    .no-chats {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;

      .wrapper-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        .wrapper-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .wrapper-title {
          padding: 16px;
        }
      }

      .wrapper-btn {
        padding: 16px;
        width: 100%;
      }
    }
    .wrapper-btn-new-chat {
      display: flex;
      justify-content: right;
      position: absolute;
      top: 87%;
      left: 80%;

      > button {
        height: 55px;
        width: 55px;
        background: #52658c;
      }
    }

    > div .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
