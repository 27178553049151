import React from "react";

import { Container } from "./styles";

// components
import Header from "../../Header";

interface Props {}

const Default: React.FC<Props> = ({ children }) => {
    return (
        <Container>
            <Header />
            {children}
        </Container>
    );
};

export default Default;
