import styled from "styled-components";

interface Props {
    colorButton?: boolean;
    toggle?: boolean;
    fullWidth?: boolean;
}

export const ProfileData = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    button.profile-data {
        background: transparent;

        img {
            width: 8px;
            height: 8px;
        }
    }

    section {
        width: 100%;
        margin-left: 8px;
        padding-top: 18px;
    }
`;

export const Container = styled.div`
    width: 100%;
`;

export const MembersData = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-sizing: border-box;
    width: 100%;

    div.scroll-users {
        width: 100%;
        height: auto;
        max-height: 430px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    section.wrapper-search {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #e7e7e7;
        padding: 10px 4px;
        padding-left: 9px;

        input {
            width: 100%;
            background: transparent;
            margin-left: 14px;
            color: #52658c;
            ::placeholder {
                color: #52658c;
            }
        }

        img.clear_text {
            cursor: pointer;
            width: 13px;
            height: 13px;
            margin-right: 17px;
            margin-top: 3px;
        }
    }
    section.wrapper-users {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        padding: 16px;

        div.wrapper-avatar {
            display: flex;
        }

        div.avatar {
            background: #f5f9ff;
            border: 1px solid #ccc;
            padding-top: 5px;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            text-align: center;
        }
        div.wrapper-names {
            margin-left: 7px;
        }

        button {
            width: 67px;
            height: 16px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 10px;

            font-size: 11px;
            font-weight: 500;
            color: #fff;
            padding: 13px 15px;

            &.removed {
                background: #ff4370;
            }
            &.added {
                background: #52658c;
            }
        }
    }

    p.wrapper-no_contacts {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    p.wrapper-is_admin {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9;
    }
`;
