import React from "react";
import Linkfy from "react-linkify";

interface Props {
    text: any;
}

const TextContent: React.FC<Props> = ({ text }) => {
    return <Linkfy properties={{ target: '_blank' }}>{text}</Linkfy>;
};

export default TextContent;
