import styled from "styled-components";

interface Props {
    height?: string;
}

export const Container = styled.div`
    width: 100%;
    height: 100vh;

    background: #ffffff;

    border-radius: 18px;
    border-top: 16px solid #52658C;
    padding: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const Image = styled.img<Props>`
    height: ${({ height }) => height || "200px"};
`;

export const Sync = styled.div`
    width: 100%;
    padding: 16px 0;
    background-color: #F6F6F6;
    /* border: 0.142222px solid rgba(82, 101, 140, 0.15); */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const ImageWrapper = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 44px;
    width: 44px;

    margin-right: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`