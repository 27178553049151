import React from "react";

import { Container } from "./styles";

interface Props {
    title?: string;
}

const Badge: React.FC<Props> = ({ children, ...rest}) => {
    return <Container isArchived={children === "Usuário arquivado"} title={rest.title}> {children} </Container>;
};

export default Badge;
