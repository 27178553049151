import React, { useEffect, useState } from "react";

import useDebounce from "../../../utils/useDebounce";
import { ContainerProfile } from "./container";
import { Container, MembersData } from "./styles";
import { Title, Email } from "./../../../components/Fonts";

// types
import { Chat } from "../../../store/modules/chats/types";
import { Contact } from "../../../store/modules/contacts/types";

interface Props {
    handleToggleInfoProfile;
    payload: {
        data: {
            me: Contact;
            otherContacts: Contact[];
            currentChat: Chat;
        };
        actions: {
            searchMember(data): void;
            update(data): void;
        };
    };
}

const Profile: React.FC<Props> = ({ payload, handleToggleInfoProfile }) => {
    const { searchMember, update } = payload.actions;
    let { currentChat, otherContacts, me } = payload.data;

    const { id, name } = currentChat;

    const contacts = currentChat.contacts.sort((a, b) => (a.name > b.name ? 1 : -1));

    const [memberName, setMemberName] = useState("");

    const [membersIds, setMembersId] = useState(contacts.map((item) => item.id));

    const [resultContact, setResultContact] = useState<any>([]);

    const debounceChange = useDebounce(searchMember);

    useEffect(() => {
        if (memberName != "") {
            if (me?.informations.isAdmin) {
                debounceChange(memberName);
            } else {
                setResultContact(contacts.filter((item) => item.name.toLowerCase().includes(memberName.toLowerCase())));
            }
        }
    }, [memberName]);

    useEffect(() => {
        setResultContact(otherContacts);
    }, [otherContacts]);

    useEffect(() => {
        if (membersIds.length != currentChat.contacts.length) {
            update(membersIds);
        }
    }, [membersIds]);

    const renderUserInfo = (item, className) => (
        <section className="wrapper-users">
            <div className="wrapper-avatar">
                <div className="avatar">{item.name.substring(0, 1)}</div>
                <div className="wrapper-names">
                    <Title title={item.name}>
                        {item.id === me.id ? "Você" : item.name}
                    </Title>
                    <Email>{item.email}</Email>
                </div>
            </div>
            {item?.informations?.isAdmin && <p className="wrapper-is_admin">Admin</p>}
        </section>
    );

    return (
        <Container>
            <MembersData>
                <div className="scroll-users">
                    {!contacts?.length && !otherContacts?.length && (
                        <p className="wrapper-no_contacts">Nenhum contato encontrado</p>
                    )}
                    {memberName != "" ? (
                        resultContact
                            ?.filter((item) => item.id != me.id && item.status !== 'disabled')
                            .map((item) => renderUserInfo(item, membersIds.includes(item.id) ? "removed" : "added"))
                    ) : (
                        <>
                            {renderUserInfo(me, false)}
                            {contacts
                                ?.filter((item) => item.id != me.id && item.status !== 'disabled')
                                .map((item) =>
                                    renderUserInfo(item, item?.informations?.chatId == id ? "removed" : "added"),
                                )}
                        </>
                    )}
                </div>
            </MembersData>
        </Container>
    );
};

export default ContainerProfile(Profile);
