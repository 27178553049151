import React from "react";

import { Container } from "./styles";

import chevronIcon from "../../../assets/icons/chevron-down.svg";
interface Items {
    label: string;
    value: string;
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label: string;
    items: Items[];
}

const Select: React.FC<Props> = ({ label, items, ...rest }) => {
    return (
        <Container>
            <label> {label} </label>

            <select {...rest}>
                <option value="" disabled>
                    Escolha uma opção
                </option>
                {items.map((item) => (
                    <option 
                        key={item.value} 
                        value={item.value}
                        title={item.label}>
                        {item.label}
                    </option>
                ))}
            </select>

            <img src={chevronIcon} alt="icon" />
        </Container>
    );
};

export default Select;
