import React from "react";

import Zoom from "react-medium-image-zoom";

import { Image, Container } from "./styles";

interface Props {
    url: string;
}

const MessageImage: React.FC<Props> = ({ url }) => {
    return (
        <Container>
            <Zoom overlayBgColorEnd="rgba(0, 0, 0, 0.8)">
                <Image src={url} />
            </Zoom>
        </Container>
    );
};

export default MessageImage;
