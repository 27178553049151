import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ContainerCreate } from "./container";

import { Container } from "./styles";

// types

// components
import { Default } from "../../../components/Layout/";
import { Subtitle, Title } from "../../../components/Fonts";
import { Button } from "../../../components/Button";
import { Text } from "../../../components/Input";

interface Props {
  payload: {
    data: {
      isEditing: boolean;
      previousWorkspace: any;
      noGroups: boolean;
    };

    actions: {
      update(data): void;
      create(data): void;
    };
  };
}

const ClassCreate: React.FC<Props> = ({ payload }) => {
  const { isEditing, previousWorkspace, noGroups } = payload.data;
  const { create, update } = payload.actions;

  const formik = useFormik({
    initialValues: {
      name: previousWorkspace?.name || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .strict()
        .required("Campo nome obrigatório!")
        .trim("Digite um nome válido!")
        .min(3, "O campo nome deve ter no mínimo 3 caracteres")
        .max(255, "O campo nome deve ter no mínimo 255 caracteres"),
    }),
    onSubmit: (values) => {
      if (isEditing) {
        update(values);
        return;
      }

      create(values);
    },
  });

  return (
    <Default>
      <Container>

        <form onSubmit={formik.handleSubmit} className="wrapper-content">
        
        <div className="wrapper-title">
          <Title fontSize="28px">{isEditing ? "Editando canal" : "Criar novo canal"}</Title>
        </div>


        <div className="wrapper-text">
          <Text
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            label="Nome"
            placeholder="Digite o nome do canal"
            maxLength={255}
          />
          {formik.errors.name && formik.touched.name ? <Subtitle error>{formik.errors.name}</Subtitle> : null}
          </div>
          <div className="wrapper-text">
           {!isEditing && (
          <Subtitle textAlign="center" fontSize="16px" lineHeight="1.3em">
           Para criar grupos, antes é necessário criar canais.
          </Subtitle>
        )} 
        </div>

          <div className="wrapper-button">
            <Button type="submit" disabled={formik.isSubmitting}>
              {noGroups ? "Próximo" : "Salvar" }
            </Button>
          </div>
        </form>

      </Container>
    </Default>
  );
};

export default ContainerCreate(ClassCreate);
