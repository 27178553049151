import { types } from "./types";

const { CONTACTS_LOAD, CONTACTS_SHOW, CONTACTS_CREATE, CONTACTS_UPDATED, CONTACTS_DESTROY } = types;

interface Result {
    type: string;
    payload?: any;
}

interface Actions {
    load(): Result;
    show(data): Result;
    create(data: { payload }): Result;
    update(data: { id: string; payload: any }): Result;
    destroy(data: { id: string }): Result;
    enableMember(data): Result;
    loadMembersEnable(): Result;
}

export const actions: Actions = {
    load: () => ({ type: CONTACTS_LOAD }),
    show: (data) => ({ type: CONTACTS_SHOW, payload: data }),
    create: (data) => ({ type: CONTACTS_CREATE, payload: data }),
    update: (data) => ({ type: CONTACTS_UPDATED, payload: data }),
    destroy: (data) => ({ type: CONTACTS_DESTROY, payload: data }),

    enableMember: (data) => ({ type: types.CONTATCS_ENABLE, payload: data }),
    loadMembersEnable: () => ({ type: types.CONTATCS_LOAD_ENABLE }),
};
