import styled from "styled-components";

interface Props {
  left: boolean;
  isArchived?: boolean;
}

export const Wrapper = styled.div<Props>`
  width: 100%;

  margin-bottom: 2px;

  display: flex;
  justify-content: ${(props) => (props.left ? "flex-start" : "flex-end")};
`;

export const Container = styled.div<Props>`
  max-width: 90%;

  padding: 8px;
  padding-bottom: 4px;
  margin: 4px;

  background: ${(props) => (props.left ? "#F5F5F5" : "#E5EDFF")};
  border-radius: ${(props) => (props.left ? "3px 20px 20px 10px" : "20px 3px 10px 20px")};

  font-size: 15px;
  color: #333333;
  font-weight: 400;

  white-space: break-all;
  word-break: break-all;
`;

export const DateMessage = styled.div<Props>`
  justify-content: ${(props) => (props.left ? "flex-start" : "flex-end")};
  text-align: end;

  display: flex;
  font-size: 10px;
  color: #828282;
  font-weight: 400;
`;
