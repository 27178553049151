import styled from "styled-components";

interface Props {
    open: boolean;
}

export const Container = styled.header`
    width: 100%;
    height: 50px;
    min-height: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    border-bottom: 1px solid rgba(82, 101, 140, 0.15);

    div.wrapper-profile {
        display: flex;
        align-items: center;
    }

    button {
        background: transparent;
        margin-right: 14px;
    }

    .group-details {
        margin-left: 14px;
    }

    label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > img {
            width: 27px;
            height: 27px;
        }
    }
`;

export const WrapperInfoProfile = styled.div<Props>`
    position: fixed;
    left: ${(props) => (props.open ? "0" : "unset")};
    bottom: 50px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
`;

export const ContentInfoProfile = styled.div<Props>`
    position: fixed;
    top: 66px;
    right: 0;
    bottom: 0;
    width: ${(props) => (props.open ? "100%" : "0px")};
    background: #fff;
    z-index: 9;
    transition: 0.4s;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
`;

export const ToggleInfo = styled.button`
    width: 36px;
    height: 36px;
    background: #f6f6f6;
    border-radius: 50%;

    :hover {
        filter: brightness(0.95);
    }
`;
