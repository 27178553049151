import React from "react";

import MessageImage from "./MessageImage";
import MessageAudio from "./MessageAudio";
import MessageDocument from "./MessageDocument";

interface Props {
    src: string;
    mimetype: string;
    filename: string;
    left: boolean;
}

const Attachment: React.FC<Props> = ({ src, mimetype, filename, left }) => {
    if (mimetype.includes("image")) return <MessageImage url={src} />;

    if (mimetype.includes("audio")) return <MessageAudio url={src} />;

    return <MessageDocument url={src} filename={filename} left={left}/>;
};

export default Attachment;
