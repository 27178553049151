import React from "react";

import { Container } from "./styles";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string;
  height?: string;
  width?: string;
}

export const IconButton: React.FC<Props> = ({ children, icon, width, height,...rest }) => {
  return (
    <Container width={width} height={height} {...rest}>
      <img src={icon}/>
    </Container>
  );
};
