import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  border-bottom: 1px solid rgba(82, 101, 140, 0.15);
  padding: 0 16px;

  section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .title-section {
    button {
      border: none;
      background-color: transparent;
      padding: 4px 8px;
      margin-right: 8px;
    }
  }

  .options-section {
    display: flex;
    gap: 8px;
  }
`;

export const Close = styled.button`
  width: 36px;
  height: 36px;
  background: #f6f6f6;
  border-radius: 50%;

  img {
    width: 15px;
    height: 15px;
  }

  :hover {
    filter: brightness(0.95);
  }
`;
