import React, { useCallback, useState, useRef } from "react";

import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";

import { ContainerMessages } from "./container";

import { Container, WrapperContent, Button, LabelMessage } from "./styles";

import ScrollToBottom from "react-scroll-to-bottom";

// types
import { Message as MessageTypes } from "../../store/modules/messages/types";
import { Chat } from "../../store/modules/chats/types";

// components
import Content from "./Content";
import { Message } from "../../components/Layout";
import { Textarea } from "../../components/Input";
import { Picker } from 'emoji-mart';

// assets
import happyIcon from "../../assets/icons/smile.svg";
import attachmentIcon from "../../assets/icons/attachment.svg";
import sendIcon from "../../assets/icons/send.svg";
import { Title } from "../../components/Fonts";

interface Props {
  payload: {
    data: {
      messages: MessageTypes[];
      userId: string;
      currentChat: Chat;
      hasNextPage: boolean;
      isLoading: boolean;
      openPiker: boolean
    };
    actions: {
      submit(data): void;
      nextPage(): void;
      setOpenPiker(param: boolean): void;
    };
  };
}

const validExtensions = [
  'audio/aac',
  'audio/mp4',
  'audio/mpeg',
  'audio/amr',
  'audio/ogg',
  'audio/opus',
  'application/vnd.ms-powerpoint',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'text/plain',
  'application/vnd.ms-excel',
  'image/jpeg',
  'image/png',
  'image/webp',
  'video/mp4',
  'video/3gpp',
]

const Messages: React.FC<Props> = ({ payload }) => {
  const { messages, userId, currentChat, hasNextPage, isLoading, openPiker } = payload.data;
  const { submit, nextPage, setOpenPiker } = payload.actions;

  const [message, setMessage] = useState<string | null>(null);
  const refInput = useRef<HTMLInputElement>(null);

  const formik = useFormik({
    initialValues: {
      text: "",
    },
    onSubmit: (values) => {
      setMessage(null);

      submit(values);
    },
  });

  const userIsArchived = (currentChat?.contacts.some((contact)=> contact.status === "disabled") && !currentChat?.isGroup)

  const onDrop = useCallback((acceptedFiles) => {
    const [file] = acceptedFiles;

    setMessage(null);

    if (!file) return;

    if (!validExtensions.includes(file.type)) {
      setMessage('Somente são permitidos arquivos dos tipos aac, mp4, mpeg, amr, ogg, opus, ppt, doc, docx, pptx, xlsx, pdf, txt, xls, jpeg, png, webp, mp4, 3gpp.');

      if (refInput?.current?.value) refInput.current.value = "";

      return;
    }

    if (file.size > 30 * 1000 * 1000) {
      setMessage("Arquivo com tamanho maior que 30mb");

      if (refInput?.current?.value) refInput.current.value = "";

      return;
    }

    submit({ text: "", file });
  }, []);

  const handleKeyUp = (event) => {
    if (!formik.values.text.trim()) return;

    if (event.key === "Enter" && !event.shiftKey) {
      formik.handleSubmit();
      setTimeout(() => {
        formik.resetForm();
      }, 300);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDrag: true,
    maxFiles: 1,
    multiple: false,
  });

  const handleChangeEmoji = (e) => {
    formik.setFieldValue('text', `${formik.values.text} ${e.native}`)
  };

  const emojiMartI18n = {
    search: 'Pesquisar',
    notfound: 'Nenhum Emoji encontrado',
    categories: {
      search: 'Resultados da busca',
      recent: 'Frequentemente usados',
      people: 'Sorrisos e pessoas',
      nature: 'Animais e natureza',
      foods: 'Comida e bebida',
      activity: 'Atividades',
      places: 'Viagem e lugares',
      objects: 'Objetos',
      symbols: 'Símbolos',
      flags: 'Bandeiras',
      custom: 'Personalizados',
    },
  }

  return (
    <Message currentChat={currentChat}>
      <Container>
        <WrapperContent isMessage={message || isLoading} >
          <ScrollToBottom mode="bottom" className="scrollcp" followButtonClassName="button-to-scroll-bottom">
            {hasNextPage && <Button onClick={nextPage}>Carregar mais</Button>}

            {!messages.length && (
                <div className="wrapper-no_messages">
                <Title fullWidth isArchived>
                  Nenhuma mensagem encontrada
                </Title>
              </div>
            )}

            {messages.map((item) => {
              const isFromMe = userId === item.contactId ? false : true;

              return (
                <Content
                  key={item.id}
                  isFromMe={isFromMe}
                  me={userId}
                  isGroup={currentChat?.isGroup}
                  {...item}
                />
              );
            })}
          </ScrollToBottom>
        </WrapperContent>
        {message && <LabelMessage danger>{message}</LabelMessage>}
        {isLoading && <LabelMessage> carregando... </LabelMessage>}
        <form onSubmit={formik.handleSubmit}>
          <button
            className="icon-action"
            type="button"
            disabled={userIsArchived}
            onClick={() => {
              setOpenPiker(!openPiker);
            }}
          >
            <img src={happyIcon} />
          </button>
          <Textarea
            disabled={userIsArchived}
            name="text"
            onChange={formik.handleChange}
            value={formik.values.text}
            onKeyUp={handleKeyUp}
            placeholder="Digite uma mensagem..."
          />
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} disabled={userIsArchived}/>

            <button
            type="button"
            className="icon-action"
            disabled={userIsArchived}
            >
              <img src={attachmentIcon} />
            </button>
          </div>
          <div className="actions">

            <div
              className="container-hidden"
              onClick={() => setOpenPiker(!openPiker)}
              style={{ display: openPiker ? 'inline-block' : 'none' }}>
              <div className="emoji-container" onClick={(e) => e.stopPropagation()}>
                <Picker
                  style={{ width: '100%', height: '100%' }}
                  disabled={userIsArchived}
                  i18n={emojiMartI18n}
                  showPreview={false}
                  showSkinTones={false}
                  onSelect={handleChangeEmoji}
                />
              </div>
            </div>
          </div>
          <button type="button" onClick={() => {
            formik.handleSubmit();
            setTimeout(() => {
              formik.resetForm();
            }, 300);
          }}
          className="icon-action"
          disabled={userIsArchived}
          >
            <img src={sendIcon} />
          </button>
        </form>
      </Container>
    </Message>
  );
};

export default ContainerMessages(Messages);
