import React, { useEffect } from "react";

import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import { OnboardContextProvider } from "./contexts/Onboard";
import { NotificationContextProvider } from "./contexts/Notifications"

import storeRedux from "./store";

import Routes from "./routes";

import GlobalStyles from "./styles/GlobalStyles";

import "react-medium-image-zoom/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import 'emoji-mart/css/emoji-mart.css'
import "normalize.css";

const App: React.FC = () => {
    const { store } = storeRedux();

    useEffect(() => {
        Notification.requestPermission();

    }, []);

    return (
        <>
            <Provider store={store}>
                <OnboardContextProvider>
                    <NotificationContextProvider>
                        <Routes />
                    </NotificationContextProvider>
                </OnboardContextProvider>
                <GlobalStyles />
            </Provider>

            <ToastContainer theme="light"/>
        </>
    );
};

export default App;
