export enum types {
    CHATS_LOAD = "@chats/CHATS_LOAD",
    CHATS_LOAD_SUCCESS = "@chats/CHATS_LOAD_SUCCESS",
    CHATS_LOAD_FAILURE = "@chats/CHATS_LOAD_FAILURE",

    CHATS_SHOW = "@chats/CHATS_SHOW",
    CHATS_SHOW_SUCCESS = "@chats/CHATS_SHOW_SUCCESS",
    CHATS_SHOW_FAILURE = "@chats/CHATS_SHOW_FAILURE",

    CHATS_CREATE = "@chats/CHATS_CREATE",
    CHATS_CREATE_SUCCESS = "@chats/CHATS_CREATE_SUCCESS",
    CHATS_CREATE_FAILURE = "@chats/CHATS_CREATE_FAILURE",

    CHATS_UPDATED = "@chats/CHATS_UPDATED",
    CHATS_UPDATED_SUCCESS = "@chats/CHATS_UPDATED_SUCCESS",
    CHATS_UPDATED_FAILURE = "@chats/CHATS_UPDATED_FAILURE",

    CHATS_DESTROY = "@chats/CHATS_DESTROY",
    CHATS_DESTROY_SUCCESS = "@chats/CHATS_DESTROY_SUCCESS",
    CHATS_DESTROY_FAILURE = "@chats/CHATS_DESTROY_FAILURE",

    CHATS_ADD_MEMBER = "@chats/CHATS_ADD_MEMBER",
    CHATS_ADD_MEMBER_SUCCESS = "@chats/CHATS_ADD_MEMBER_SUCCESS",
    CHATS_ADD_MEMBER_FAILURE = "@chats/CHATS_ADD_MEMBER_FAILURE",

    CHATS_REMOVE_MEMBER = "@chats/CHATS_REMOVE_MEMBER",
    CHATS_REMOVE_MEMBER_SUCCESS = "@chats/CHATS_REMOVE_MEMBER_SUCCESS",
    CHATS_REMOVE_MEMBER_FAILURE = "@chats/CHATS_REMOVE_MEMBER_FAILURE",

    SET_CURRENT_CONTACT = "@contact/SET_CURRENT_CONTACT",
    SET_CURRENT_CONTACT_SUCCESS = "@contact/SET_CURRENT_CONTACT_SUCCESS",

    SET_PAGE = "@PAGE/SET_PAGE",
    SET_PAGE_SUCCESS = "@PAGE/SET_PAGE_SUCCESS",

    CHATS_ON_SOCKET = "@chats/MESSAGES_ON_SOCKET",
    CHATS_REMOVED_SOCKET = "@chats/CHATS_REMOVED_SOCKET",
    CHATS_ADDED_SOCKET = "@chats/CHATS_ADDED_SOCKET",
}

export interface File {
    id: string;
    filename: string;
    messageId: string;
    mimetype: string;
    size: string;
    url: string;
    updatedAt: Date;
    createdAt: Date;
}

export interface LastMessage {
    id: string;
    chatId: string;
    contactId: string;
    replyTo: string;
    text: string;
    type: string;
    contact: Contact;
    file: File;
    createdAt: Date;
    updatedAt: Date;
}

export interface Informations {
    chatId: string;
    contactId: string;
    createdAt: Date;
    deletedAt: Date;
    id: number;
    isAdmin: boolean;
    unread: number;
    updatedAt: Date;
}

export interface Contact {
    id: string;
    email: string;
    name: string;
    password: string;
    status: string;
    isAdmin: boolean;
    phone: string;
    updatedAt: Date;
    createdAt: Date;
    informations: Informations;
    webhookId: string;
}

export interface Workspace {
    id: string;

    name: string;

    createdAt: Date;
    updatedAt: Date;
}

export interface Chat {
    id: string;
    lastMessageId: string;
    lastMessage: LastMessage;
    isGroup: boolean;
    name: string;
    contacts: Contact[];
    workspace: Workspace;
    createdAt: Date;
    updatedAt: Date;
}

export interface PaginateById {
    currentPage: number;
    lastPage: number;
}

export interface IById {
    [key: string]: any;
}

export interface StateChats {
    allId: string[];
    byId: IById;
    currentContact: any;
    paginateById: IById;
}
