import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

// actions
import { actions } from "../../store/modules/chats/actions";
import { actions as actionsWorkspaces }   from "../../store/modules/workspaces/actions";

// selectors
import { dataChatsGroups } from "../../store/modules/chats/selectors";
import { data  } from "../../store/modules/workspaces/selectors";

export const ContainerGroups = (Component) => {
    const Container = () => {
        useEffect(() => {
            handleLoad();
        }, []);

        const dispatch = useDispatch();

        const chats = useSelector(dataChatsGroups);

        const workspaces = !!useSelector(data)?.length;   
            

        const handleLoad = () => {
            dispatch(actions.load());
            dispatch(actionsWorkspaces.load());
        };

        const handleDestroy = (id) => {
            dispatch(actions.destroy(id));
        };

        return (
            <Component
                payload={{
                    data: {
                        chats,
                        workspaces
                    },
                    actions: {
                        destroy: handleDestroy,
                    },
                }}
            />
        );
    };

    return Container;
};
