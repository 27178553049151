import styled from "styled-components";

export const Container = styled.textarea`
    width: 100%;
    min-height: 25px;

    background: #ffffff;

    border-radius: 20px;

    padding: 0 15px;
    padding-top: 11px;

    font-size: 10px;

    resize: none;

    :disabled {
        cursor: not-allowed;
    }
`;
