import styled from "styled-components";

export const Container = styled.button`
    height: 52px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #52658C;
    border-radius: 32px;

    font-weight: 700;
    font-size: 18px;
    line-height: 1em;
    color: #fff;

    transition: 0.2s;

    :hover {
        opacity: 0.85;
    }

    :disabled {
        cursor: not-allowed;
        background: #666;
        color: #fff;
    }
`;
