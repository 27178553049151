import { useRef } from "react";

export default function useDebounce(fn, delay = 500) {
    const timeoutRef = useRef(null);

    function debounceFn(...params) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(function () {
            fn(...params);
        }, delay);
    }

    return debounceFn;
}
