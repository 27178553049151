import React from "react";

import { ContainerChats } from "./container";

import { Container } from "./styles";

// types
import { Chat } from "../../store/modules/chats/types";
import { Workspaces } from "../../store/modules/workspaces/types";

// components
import { Default } from "../../components/Layout";
import { Title } from "../../components/Fonts";
import Card from "../../components/Card";
import AvatarGroup from "../../components/AvatarGroup";
import Unread from "../../components/Unread";
import Badge from "../../components/Badge";

interface Props {
    payload: {
        data: {
            chats: Chat[];
            currenteWorkspace: Workspaces;
            userAuthId: string;
        };
        actions: {
            setCurrentContact(data): void;
            unread(chat, userId): number;
        };
    };
}

const Chats: React.FC<Props> = ({ payload }) => {
    const { chats, currenteWorkspace, userAuthId } = payload.data;
    const { setCurrentContact, unread } = payload.actions;

    if (!chats) return <p> loading </p>;

    return (
        <Default>
            <Container>
                <div className="wrapper-content">
                    <div className="content">
                        {!chats.filter((item) => item.isGroup).length && (
                            <div className="no-groups">
                                <Title isArchived>Nenhum grupo encontrado nesse canal!</Title>
                            </div>
                        )}
                        {chats.map(
                            (item) =>
                                item.isGroup && (
                                    <Card key={item.id} onClick={() => setCurrentContact(item)}>
                                        <AvatarGroup members={item?.contacts} />
                                        <div className="profile-data">
                                            <div className="wrapper-badge">
                                                <Title fontSize="16px" style={{ marginBottom: "4px" }}>
                                                    {item.isGroup && item.name}
                                                </Title>
                                            </div>
                                            <div className="wrapper-message">
                                                <Badge>{item?.workspace?.name}</Badge>
                                            </div>
                                        </div>

                                {item?.contacts?.length > 0 && unread(item, userAuthId) > 0 && (
                                    <Unread>{unread(item, userAuthId)}</Unread>
                                )}
                            </Card>
                        ))}
                    </div>
                </div>
            </Container>
        </Default>
    );
};

export default ContainerChats(Chats);
