import React from "react";

import { Container } from "./styles";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

const Text: React.FC<Props> = ({ children, ...rest }) => {
    return (
        <Container>
            {rest.label && <label> {rest.label} </label>}

            <input {...rest} />
        </Container>
    );
};

export default Text;
