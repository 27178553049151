import styled from "styled-components";

export const Container = styled.div`
    background: #fff;

    > .wrapper-content {
        width: 100%;
        height: calc(100vh - 66px);

        .no-groups {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p.back-channels {
            display: flex;
            align-items: center;

            cursor: pointer;

            color: #007dab;

            font-weight: 600;
            font-size: 11px;

            :hover {
                opacity: 0.5;
            }

            img {
                width: 15px;
                height: 15px;
            }
        }
    }

    > .wrapper-content .content {
        width: 100%;
        height: 100%;

        overflow-y: auto;

        span.unread {
            position: absolute;
            top: -7px;
            right: 5px;

            background: #5c9dfe;
            border: 2px solid #f5f9ff;
            border-radius: 50%;

            font-size: 8px;
            width: 15px;
            height: 15px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        > div .profile-data {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 7px;
            width: 100%;

            > .wrapper-badge {
                display: flex;
            }

            > .wrapper-message {
                display: flex;
                align-items: center;

                p:nth-child(2) {
                    margin-left: 7px;
                }
            }
        }
    }
`;
