import { Reducer } from "redux";
import { StateWorkspaces, types, Workspaces } from "./types";

import {
  createTreeAllId,
  createTreeById,
  insertElementAllId,
  insertElementById,
  deleteElementAllId,
} from "../common/selectors";

import { types as typesAuth } from "../auth/types";

const initialState: StateWorkspaces = {} as StateWorkspaces;

const reducerById: Reducer = (state = {}, action): { [key: string]: any } => {
  const { type, payload } = action;

  switch (type) {
    case types.WORKSPACES_LOAD_SUCCESS:
      return createTreeById(state, payload);
    case types.WORKSPACES_SHOW_SUCCESS:
    case types.WORKSPACES_CREATE_SUCCESS:
    case types.WORKSPACES_SHOW_CHATS_SUCCESS:
      return insertElementById(state, payload);
    case types.WORKSPACES_DESTROY_SUCCESS:
      return state;
    case typesAuth.AUTH_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
};

const reducerAllId: Reducer = (state = [], action): string[] => {
  const { type, payload } = action;

  switch (type) {
    case types.WORKSPACES_LOAD_SUCCESS:
      return createTreeAllId(state, payload);
    case types.WORKSPACES_SHOW_SUCCESS:
    case types.WORKSPACES_CREATE_SUCCESS:
    case types.WORKSPACES_SHOW_CHATS_SUCCESS:
      return insertElementAllId(state, payload);
    case types.WORKSPACES_DESTROY_SUCCESS:
      return deleteElementAllId(state, payload);
    case typesAuth.AUTH_LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
};

const reducerCurrentWorkspace: Reducer = (state = {} as Workspaces, action): string[] => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_CURRENT_WORKSPACE:
      return payload;
    default:
      return state;
  }
};

const reducerPage: Reducer = (state = {}, action) => {
  const { type, payload} = action;

  switch (type) {
    case types.WORKSPACES_SET_PAGE:
      return payload;
    default:
      return state;
  }
}

export const reducers: Reducer<StateWorkspaces> = (state = initialState, actions) => ({
  byId: reducerById(state.byId, actions),
  allId: reducerAllId(state.allId, actions),
  currentWorkspace: reducerCurrentWorkspace(state.currentWorkspace, actions),
  reducerPage: reducerPage(state.reducerPage, actions),
});
