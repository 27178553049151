import styled from "styled-components";

interface Props {
    color?: string;
}

export const Container = styled.button<Props>`
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 3px;

    width: 31px;
    height: 31px;

    padding: 9px;

    background: ${(props) => props.color || "#52658c"};

    box-sizing: border-box;
    border-radius: 10px;

    transition: 0.2s;

    > .active {
        border: 0.5px solid #ffffff;
    }

    > img {
        width: 20px;
        height: 20px;
    }

    :hover {
        opacity: 0.5;
        border: 0.5px solid #ffffff;
    }
`;
