import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ApplicationState } from "../../../store";

// actions
import { actions } from "../..//../store/modules/chats/actions";

// hooks
import { useAsync } from "../../../hooks/useAsync";

// selectors
import { userIdAuthenticated } from "../../../store/modules/auth/selectors";
import { getDetailsChatById } from "../../../store/modules/chats/selectors";

//resource
import { mapContact } from "../../../resources/contacts/api";

export const ContainerProfile = (Component) => {
    const Container = ({ handleToggleInfoProfile }) => {
        const dispatch = useDispatch();

        const { execute, value } = useAsync(mapContact.searchContact);

        const { id } = useParams();

        const userId = useSelector(userIdAuthenticated);

        const currentChat = useSelector((state: ApplicationState) => getDetailsChatById(state, id));

        const me = currentChat.contacts.find((item) => item.id == userId);

        const handleSearchMember = (name) => {
            const query = {
                where: {
                    $or: {
                        name: {
                            $like: `%${name}%`,
                        },
                        email: {
                            $like: `%${name}%`,
                        },
                    },
                },
                order: [["name", "ASC"]],
            };
            execute(query);
            return;
        };

        const handleUpdate = (data) => {
            dispatch(
                actions.update({
                    contactsIds: data,
                    id,
                    adminIds: currentChat.contacts.filter((item) => item.informations.isAdmin).map((item)=> ({
                        value: item.id
                    })),
                }),
            );
        };

        return (
            <Component
                handleToggleInfoProfile={handleToggleInfoProfile}
                payload={{
                    data: {
                        otherContacts: value?.data,
                        currentChat,
                        userId,
                        me,
                    },
                    actions: {
                        searchMember: handleSearchMember,
                        update: handleUpdate,
                    },
                }}
            />
        );
    };

    return Container;
};
