import { eventChannel, buffers } from "redux-saga";
import io from "socket.io-client";

const socketURL = process.env.SOCKET_URL || "http://localhost:7001";

export const socketInstance = {};

export function createSocketConnection(token) {
    if (socketInstance.ws) return socketInstance.ws;

    const socket = io(socketURL, {
        query: {
            token,
        },
        transports: ["websocket"],
        upgrade: false,
    });

    socketInstance.ws = socket;

    return socket;
}

export const CREATED = "created";
export const UPDATED = "updated";
export const DESTROYED = "destroyed";
export const REMOVED = "removed";
export const ADDED = "added";

export const defaultEvents = [CREATED, UPDATED, DESTROYED, REMOVED, ADDED];

export function createSocketChannel(socket, namespace) {
    const events = defaultEvents;

    return eventChannel((emit) => {
        const listeners = {};

        events.forEach((event) => {
            listeners[event] = (data) => emit({ event, ...data });
            socket.on(`${namespace}.${event}`, listeners[event]);
        });

        return () => {
            events.forEach((event) => {
                socket.off(event, listeners[event]);
                delete listeners[event];
            });
        };
    }, buffers.expanding(100));
}
