import React from "react";

import { Container } from "./styles";

interface Props {
    name?: string;
    size?: "sm" | "lg";
}

const Avatar: React.FC<Props> = ({ name = '', size = "lg" }) => {
    const firstLetter = name ? name.substring(0, 1) : "D";

    return (
        <Container sizeLetter={size}>
            {firstLetter}
        </Container>
    );
};

export default Avatar;
