import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    > label {
        font-size: 16px;
        font-weight: 400;
        margin-left: 17px;
    }
    
    > input {
        width: 100%;
        background: transparent;
        font-size: 16px;
        border: 1px solid rgba(82, 101, 140, 0.15);
        padding: 8px 16px;
        border-radius: 20px;
    }
`;
