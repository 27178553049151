import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// actions
import { actions as actionsChats } from "../../store/modules/chats/actions";

// selectors
import { getCurrentWorkspaceById } from "../../store/modules/workspaces/selectors";
import { userIdAuthenticated } from "../../store/modules/auth/selectors";
import { dataChats } from "../../store/modules/chats/selectors";

type paramsType = {
    id: string
}

export const ContainerChats = (Component) => {
    const Container = () => {
        const dispatch = useDispatch();

        const { id } = useParams<paramsType>();

        const userAuthId = useSelector(userIdAuthenticated);

        const chats = useSelector(dataChats);

        const currenteWorkspace = useSelector((state: any) => getCurrentWorkspaceById(state, id));

        const handleSetCurrentContact = (chat) => {
            dispatch(actionsChats.setCurrentContact({ contactId: null, chatId: chat.id }));
        };

        const handleUnread = (chat, userId) => {
            return chat?.contacts?.find((contact) => contact.id === userId)?.informations?.unread;
        };

        return (
            <Component
                payload={{
                    data: {
                        chats,
                        currenteWorkspace,
                        userAuthId,
                    },
                    actions: {
                        setCurrentContact: handleSetCurrentContact,
                        unread: handleUnread,
                    },
                }}
            />
        );
    };

    return Container;
};
