import { createSelector } from "reselect";

import formatDate from "../../../utils/formatDate";

import { getElements } from "../common/selectors";

import { ApplicationState } from "./../../index";
import { Message } from "./types";

export const stateMessages = (state: ApplicationState) => getElements<Message>(state.messages);

export const dataMessages = createSelector(stateMessages, (messages) => {
    return messages;
});

export const messagesByChatId = createSelector(
    stateMessages,
    (_, chatId) => chatId,
    (messages, chatId) =>
        messages.filter((item) => item.chatId === chatId).sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
);

export const groupMessageFromDate = createSelector(
    stateMessages,
    (_, chatId) => chatId,
    (messages, chatId) => {
        const messagesFromChat = messages
            .filter((item) => item.chatId === chatId)
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));

        const response = messagesFromChat.reduce((acc, cur) => {
            const date = formatDate(new Date(cur.createdAt));

            if (acc[date]) {
                acc[date].push(cur);

                return acc;
            }

            return {
                ...acc,
                [date]: [cur],
            };
        }, {});

        return Object.entries(response);
    },
);
