import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span { 
    color: #52658C;
    font-size: 54px;
    font-weight: 700;
  }

  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
  }

`