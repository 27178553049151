import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { HistoryTypes } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

// actions
import { actions } from "../../../store/modules/workspaces/actions";

// selectors
import { getCurrentWorkspaceById } from "../../../store/modules/workspaces/selectors";

// hooks

// resource

export const ContainerCreate = (Component) => {
  const Container = () => {
    const dispatch = useDispatch();

    const history = useHistory<HistoryTypes>();

    const { id } = useParams<{ id: string }>();

    const isEditing = !!id;

    const noGroups = history.location?.state?.noGroups;

    const previousWorkspace = isEditing
      ? useSelector((state: ApplicationState) => getCurrentWorkspaceById(state, id))
      : null;

    const handleNewGroup = () => {
      if (noGroups) {
        history.push("/groups/create", { noGroups: true });
      }
    };

    const handleCreate = (data) => {
      dispatch(actions.create(data));
      handleNewGroup();
    };

    const handleUpdate = (data) => {
      dispatch(actions.update({ ...data, id }));
      handleNewGroup();
    };

    return (
      <Component
        payload={{
          data: {
            isEditing,
            previousWorkspace,
            noGroups
          },
          actions: {
            create: handleCreate,
            update: handleUpdate,
          },
        }}
      />
    );
  };

  return Container;
};
