import styled from "styled-components";

interface Props {
    latest?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;

    width: 50px;
    max-width: 50px;
    min-width: 50px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const Item = styled.div<Props>`
    width: 20px;
    height: 20px;

    margin-top: 2px;
    margin-right: 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    background: ${(props) => (props.latest ? "#5C9DFE" : "#f5f9ff")};
    border: ${(props) => (props.latest ? "1px solid #fff" : "1px solid #ccc")};
    border-radius: 50%;
    text-align: center;

    color: ${(props) => (props.latest ? "#fff" : "unset")};

    font-size: 10px;
`;
