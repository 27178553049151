import api from "../../service/api";

const webhookResource = {
    create: (data) => api.post("/webhooks", data).then((response) => response.data),
    syncContact: (data) => api.post("/webhooks/sync", data).then((response) => response.data),
    resyncContact: (data) => api.post("/webhooks/resync", data).then((response) => response.data),
    checkToken: (data) => api.post("/webhooks/token", data).then((response) => response.data),
};

export default webhookResource;
