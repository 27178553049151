import React from "react";

import { Player } from "./styles";

interface Props {
    url: string;
}

const MessageAudio: React.FC<Props> = ({ url }) => {
    return (
        <Player controls controlsList="nodownload novolume">
            <source src={url} type="audio/ogg" />
        </Player>
    );
};

export default MessageAudio;
