import React from "react";
import SelectComponent from "react-select";

import chevronIcon from "../../../assets/icons/chevron-down.svg";

import { Container, DropdownIndicatorIcon } from "./styles";

interface Items {
    label: string;
    value: string;
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label: string;
    items: Items[];
    handleChange(data): void;
    value: any;
}

const optionsStyle = {
    control: (base, state) => ({
        // ...base,
        display: "flex",
        fontSize: 16,
        padding: "8px 16px",
        border: "1px solid rgba(82, 101, 140, 0.15)",
        borderRadius: "20px",
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        ...state,
        width: 17,
        height: 17,
        color: "#52658c",
    }),
};

const DropdownIndicatorComponent = () => {
    return <DropdownIndicatorIcon src={chevronIcon} alt="icon" />;
};

const Select: React.FC<Props> = ({ label, items, handleChange, value, ...rest }) => {
    return (
        <Container>
            <label> {label} </label>

            <SelectComponent
                closeMenuOnSelect={false}
                defaultValue={null}
                onChange={(value) => handleChange(value)}
                isMulti
                value={value}
                options={items}
                placeholder="Pesquise um administrador"
                styles={optionsStyle}
                className="basic-multi-select"
                classNamePrefix="select"
                noOptionsMessage={() => 'Sem opções'}
                components={{ DropdownIndicator: DropdownIndicatorComponent }}
            ></SelectComponent>
        </Container>
    );
};

export default Select;
