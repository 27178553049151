import { all, takeLatest } from "redux-saga/effects";

// types
import { types as typesAuth } from "./auth/types";
import { types as typesChats } from "./chats/types";
import { types as typesContacts } from "./contacts/types";
import { types as typesMessages } from "./messages/types";
import { types as typesWorkspaces } from "./workspaces/types";

// sagas
import authSagas from "./auth/sagas";
import chatsSagas from "./chats/sagas";
import contactsSagas from "./contacts/sagas";
import messagesSagas from "./messages/sagas";
import workspacesSagas from "./workspaces/sagas";

export default function* () {
  return yield all([
    // auth
    takeLatest(typesAuth.AUTH_LOGIN, authSagas.login),
    takeLatest(typesAuth.AUTH_LOGOUT, authSagas.logout),
    takeLatest(typesAuth.AUTH_REGISTER, authSagas.register),
    takeLatest(typesAuth.AUTH_ME, authSagas.me),

    takeLatest(typesAuth.AUTH_LOGIN_SUCCESS, authSagas.connectSocket),
    takeLatest(typesAuth.AUTH_LOGOUT_SUCCESS, authSagas.disconnetSocket),

    // workspaces
    takeLatest(typesWorkspaces.WORKSPACES_LOAD, workspacesSagas.load),
    takeLatest(typesWorkspaces.WORKSPACES_SHOW, workspacesSagas.show),
    takeLatest(typesWorkspaces.WORKSPACES_SHOW_CHATS, workspacesSagas.showChats),
    takeLatest(typesWorkspaces.WORKSPACES_CREATE, workspacesSagas.create),
    takeLatest(typesWorkspaces.WORKSPACES_UPDATE, workspacesSagas.update),
    takeLatest(typesWorkspaces.WORKSPACES_DESTROY, workspacesSagas.destroy),

    // chats
    takeLatest(typesChats.CHATS_LOAD, chatsSagas.load),
    takeLatest(typesChats.CHATS_SHOW, chatsSagas.show),
    takeLatest(typesChats.CHATS_CREATE, chatsSagas.create),
    takeLatest(typesChats.CHATS_UPDATED, chatsSagas.update),
    takeLatest(typesChats.CHATS_DESTROY, chatsSagas.destroy),
    takeLatest(typesChats.SET_CURRENT_CONTACT, chatsSagas.setCurrentContact),
    takeLatest(typesChats.CHATS_ADD_MEMBER, chatsSagas.addMember),
    takeLatest(typesChats.CHATS_REMOVE_MEMBER, chatsSagas.removeMember),
    takeLatest(typesChats.SET_PAGE, chatsSagas.setPageByChatId),

    // contacts
    takeLatest(typesContacts.CONTACTS_LOAD, contactsSagas.load),
    takeLatest(typesContacts.CONTACTS_SHOW, contactsSagas.show),
    takeLatest(typesContacts.CONTACTS_CREATE, contactsSagas.create),
    takeLatest(typesContacts.CONTACTS_UPDATED, contactsSagas.update),
    takeLatest(typesContacts.CONTACTS_DESTROY, contactsSagas.destroy),
    takeLatest(typesContacts.CONTATCS_ENABLE, contactsSagas.enableMember),
    takeLatest(typesContacts.CONTATCS_LOAD_ENABLE, contactsSagas.loadMemberEnables),

    // messages
    takeLatest(typesMessages.MESSAGES_LOAD, messagesSagas.load),
    takeLatest(typesMessages.MESSAGES_CREATE, messagesSagas.create),
    takeLatest(typesMessages.MESSAGES_UPDATED, messagesSagas.update),
    takeLatest(typesMessages.MESSAGES_DESTROY, messagesSagas.destroy),
    takeLatest(typesMessages.MESSAGES_ON_SOCKET, messagesSagas.notificationSaga),
    takeLatest(typesMessages.MESSAGES_LOAD_MORE, messagesSagas.loadMore),

    // REHYDRATE

    // socket
    takeLatest(typesAuth.SOCKET_CONNECTED, messagesSagas.watchOnMessages),
    takeLatest(typesAuth.SOCKET_CONNECTED, chatsSagas.watchOnChats),
    takeLatest(typesAuth.SOCKET_CONNECTED, contactsSagas.watchOnContacts),
  ]);
}
