import { Reducer } from "redux";
import { StateMessages, types } from "./types";

import { createTreeAllId, createTreeById, insertElementAllId, insertElementById } from "../common/selectors";

import { types as typesAuth } from "../auth/types";

const { MESSAGES_LOAD_SUCCESS, MESSAGES_CREATE_SUCCESS, MESSAGES_ON_SOCKET, MESSAGES_LOAD_MORE_SUCCESS } = types;

const initialState: StateMessages = {} as StateMessages;

const reducerById: Reducer = (state = {}, action): { [key: string]: any } => {
    const { type, payload } = action;

    switch (type) {
        case MESSAGES_LOAD_SUCCESS:
        case MESSAGES_LOAD_MORE_SUCCESS:
            return createTreeById(state, payload);
        case MESSAGES_CREATE_SUCCESS:
        case MESSAGES_ON_SOCKET:
            return insertElementById(state, payload);
        case typesAuth.AUTH_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

const reducerAllId: Reducer = (state = [], action): string[] => {
    const { type, payload } = action;

    switch (type) {
        case MESSAGES_LOAD_SUCCESS:
        case MESSAGES_LOAD_MORE_SUCCESS:
            return createTreeAllId(state, payload);
        case MESSAGES_CREATE_SUCCESS:
        case MESSAGES_ON_SOCKET:
            return insertElementAllId(state, payload);
        case typesAuth.AUTH_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
};

export const reducers: Reducer<StateMessages> = (state = initialState, actions) => ({
    byId: reducerById(state.byId, actions),
    allId: reducerAllId(state.allId, actions),
});
