import React from "react";

import { ContainerOnboard } from "./container";

import { Container, Image } from "./styles";

import { Subtitle, Title } from "../../components/Fonts";
import { Button } from "../../components/Button";

import digisac from "../../assets/icons/digisac.png";
import { User } from "../../store/modules/auth/types";

interface Props {
    payload: {
        data: {
            user: User;
            isSync: boolean;
        };
        actions: {
            init(): void;
        };
    };
}

const Onboard: React.FC<Props> = ({ payload }) => {
    const { user, isSync } = payload.data;

    const { init } = payload.actions;

    return (
        <Container>
            <Image src={digisac} alt="digisac" />
            <Title fullWidth fontWeight="600" fontSize="28px" textAlign="center">
                Seja bem-vindo ao chat interno digisac
            </Title>
            <Subtitle fontSize="16px" textAlign="center" style={{ margin: "32px 0" }} lineHeight="1.3em">
                {user?.isAdmin
                    ? `Para configurar seu primeiro acesso, sincronize a digisac com o Chat Interno e,
                        logo depois, habilite os contatos que utilizarão este recurso.`
                    : `Para continuar utilizando os serviços do Chat Interno,
                        solicite ao usuário adminstrador que faça a sincronização do mesmo com a digisac.`}
        </Subtitle>
        {user?.isAdmin && (
            <Button onClick={init} disabled={isSync}>
            Sincronizar
            </Button>
        )}
        </Container>
    );
};

export default ContainerOnboard(Onboard);
