import React, { useRef, useEffect, useState } from "react";

import { Container, WrapperItem, Item, TextItem, Label } from "./styles";

// assets
import moreIcon from "../../assets/icons/dots.svg";

interface ItemProps {
    label: string;
    icon?: any;
    handleClick?: (rest) => void;
    maxWidth?: boolean;
}

interface Props {
    label?: string;
    icon?: any;
    maxWidth?: boolean;
    items: ItemProps[];
}

const Dropdown: React.FC<Props> = ({ label, icon, maxWidth, items }) => {
    const [dropdownShow, setDropdownShow] = useState(false);
    const menu = useRef<HTMLDivElement>(null);

    function handleToggleDropdown() {
        setDropdownShow(!dropdownShow);
    }

    function handleClickOutside(event) {
        if (menu.current && !menu.current.contains(event.target)) {
            setDropdownShow(false);
        }
    }

    useEffect(() => {
        if (typeof window !== undefined) {
            window.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Container ref={menu}>
            <button onClick={handleToggleDropdown}>
                <img src={icon || moreIcon} />
            </button>
            {dropdownShow && (
                <WrapperItem maxWidth>
                    {label && <Label>{label}</Label>}
                    {items.map((item, index) => (
                        <Item key={index} onClick={(event) => {
                            if(item.handleClick) item.handleClick(event)
                            setDropdownShow(false);
                        }}>
                            {item.icon && <img src={item.icon} />}
                            <TextItem>{item.label}</TextItem>
                        </Item>
                    ))}
                </WrapperItem>
            )}
        </Container>
    );
};

export default Dropdown;
