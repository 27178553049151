export enum types {
    AUTH_LOGIN = "@auth/AUTH_LOGIN",
    AUTH_LOGIN_SUCCESS = "@auth/AUTH_LOGIN_SUCCESS",
    AUTH_LOGIN_FAILURE = "@auth/AUTH_LOGIN_FAILURE",

    AUTH_LOGOUT = "@auth/AUTH_LOGOUT",
    AUTH_LOGOUT_SUCCESS = "@auth/AUTH_LOGOUT_SUCCESS",
    AUTH_LOGOUT_FAILURE = "@auth/AUTH_LOGOUT_FAILURE",

    AUTH_REGISTER = "@auth/AUTH_REGISTER",
    AUTH_REGISTER_SUCCESS = "@auth/AUTH_REGISTER_SUCCESS",
    AUTH_REGISTER_FAILURE = "@auth/AUTH_REGISTER_FAILURE",

    AUTH_ME = "@auth/AUTH_ME",
    AUTH_ME_SUCCESS = "@auth/AUTH_ME_SUCCESS",
    AUTH_ME_FAILURE = "@auth/AUTH_ME_FAILURE",

    SOCKET_CONNECTED = "@auth/SOCKET_CONNECTED",
    SOCKET_CONNECT_FAILURE = "@auth/SOCKET_CONNECT_FAILURE",

    SOCKET_DISCONNECTED = "@auth/SOCKET_DISCONNECTED",
    SOCKET_DISCONNECT_FAILURE = "@auth/SOCKET_DISCONNECT_FAILURE",
}

export interface Auth {
    token: string;
    user: User;
}

export interface User {
    id: string;
    name: string;
    phone: string;
    email: string;
    isAdmin: boolean;
    updatedAt: Date;
    createdAt: Date;
}

export interface StateAuth {
    token: string;
    user: User;
}
