import React from "react";

import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { tokenAuthenticated } from "../store/modules/auth/selectors";

interface PropsPrivateRoute {
    exact: boolean;
    path: string;
    component: React.ElementType;
}

const PrivateRoute: React.FC<PropsPrivateRoute> = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector(tokenAuthenticated);

    return (
        <Route
            {...rest}
            render={(matchProps) =>
                isAuthenticated ? <Component {...matchProps} /> : <Redirect to={{ pathname: "/unauthorized" }} />
            }
        />
    );
};

export default PrivateRoute;
