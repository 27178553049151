import React from "react";

import { Container } from "./styles";

// components
import { Default } from "../../components/Layout";
import { Subtitle, Title, Text } from "../../components/Fonts";
import Card from "../../components/Card";

// assets
import linkIcon from "../../assets/new-icons/link.svg";

interface Props {}

const Settings: React.FC<Props> = ({ children }) => {
    return (
        <Default>
            <Container>
                <Title> Configurações </Title>
                <Subtitle>
                    Visualize abaixo os dados e opcoes de configuracoes que voce pode precisar para personalizar o seu
                    chat-interno
                </Subtitle>

                <section>
                    <ul>
                        <li>
                            <Title> URL </Title>
                        </li>
                    </ul>
                    <Card border={false} flex-column>
                        <div className="wrapper-link">
                            <Subtitle> https://www.digisac.com.br </Subtitle> <img src={linkIcon} />
                        </div>
                    </Card>
                </section>
                <section>
                    <ul>
                        <li>
                            <Title> URL disponível para vinculo </Title>
                        </li>
                    </ul>
                    <Card border={false} flex-column>
                        <div className="wrapper-link">
                            <Subtitle>https://www.digisac.com.br/bauru</Subtitle> <img src={linkIcon} />
                        </div>
                        <div className="wrapper-link">
                            <Subtitle>https://www.digisac.com.br/sao-paulo</Subtitle> <img src={linkIcon} />
                        </div>
                    </Card>
                </section>

                <section>
                    <ul>
                        <li>
                            <Title> URL vinculada </Title>
                        </li>
                    </ul>
                    <Card border={false} flex-column>
                        <div className="wrapper-link">
                            <Subtitle>https://www.digisac.com.br/sao-paulo</Subtitle> <span>Vinculado</span>
                        </div>
                    </Card>
                </section>
            </Container>
        </Default>
    );
};

export default Settings;
