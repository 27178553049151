import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

// actions
import { userIdAuthenticated } from "../../store/modules/auth/selectors";
import { actions } from "../../store/modules/chats/actions";
// selectors

// hooks
import { useAsync } from "../../hooks/useAsync";

// resource
import { mapContact } from "../../resources/contacts/api";
import { chatsResource } from "../../resources/chats/api";
import { Contact } from "../../store/modules/chats/types";

export const ContainerUsers = (Component) => {
  const Container = () => {
    const dispatch = useDispatch();
    const { execute, value } = useAsync(mapContact.searchContact);
    const { execute: createNewChat, value: responseNewChat } = useAsync(chatsResource.newChat);
    const { execute: searchNewChat } = useAsync(chatsResource.searchChats);
    const userAuthId = useSelector(userIdAuthenticated);

    const [data, setData] = useState<Contact[]>([]);
    const [paginate, setPaginate] = useState({});

    useEffect(() => {
      const initQuery = {
        page: 1,
        where: {
          id: {
            $ne: userAuthId,
          },
          status: "enabled"
        },
        order: [["name", "ASC"]],
      };
      execute(initQuery);
    }, []);

    const handleLoadChats = async () => {
      await dispatch(actions.load());
      await searchNewChat({ filter: "all" });
    };

    const handleSetChat = async () => {
      setTimeout(() => {
        dispatch(
          actions.setCurrentContact({
            chatId: responseNewChat.chatId,
            contactId: responseNewChat.contactId,
          }),
        );
      }, 200);
    };

    useEffect(() => {
      if (responseNewChat) {
        handleLoadChats();
        handleSetChat();
      }
      
    }, [responseNewChat]);

    useEffect(() => {
      if (value?.data) {
        setData([...data, ...value?.data]);
        setPaginate({
          currentPage: value.currentPage,
          lastPage: value.lastPage,
        });
      }
    }, [value]);

    const handleNewChat = (contactId, webhookId) => {
      createNewChat({
        myContactId: userAuthId,
        contactId,
        webhookId,
      });
    };

    const handleSearchMember = (data) => {
      const { name, page = 1, keep = false } = data;
      if (!keep) {
        setData([]);
      }
      const query = {
        page: page,
        where: {
          $or: {
            name: {
              $like: `%${name}%`,
            },
            email: {
              $like: `%${name}%`,
            },
          },
          status: "enabled"
        },
        order: [["name", "ASC"]],
      };
      execute(query);
    };

    return (
      <Component
        payload={{
          data: {
            contacts: data.filter((contact) => contact?.id != userAuthId),
            paginate,
          },
          actions: {
            newChat: handleNewChat,
            searchMember: handleSearchMember,
          },
        }}
      />
    );
  };

  return Container;
};
