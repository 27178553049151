import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

// root reducers and sagas
import rootReducers from "./modules/rootReducers";
import rootSagas from "./modules/rootSagas";

// default types application
import { Auth } from "./modules/auth/types";
import { StateChats } from "./modules/chats/types";
import { StateContacts } from "./modules/contacts/types";
import { StateMessages } from "./modules/messages/types";
import { StateWorkspaces } from "./modules/workspaces/types";

type StatusValues = "disabled" | "enabled" | "all";

const persistConfig = {
  key: "root",
  storage,
};

export interface ApplicationState {
  auth: Auth;
  chats: StateChats;
  contacts: StateContacts;
  messages: StateMessages;
  workspaces: StateWorkspaces;
}
export interface HistoryTypes {
  status?: StatusValues;
  isFirstAccess?: boolean;
  filter?: string;
  search?: string;
  noGroups?: boolean;
}

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = rootReducers;

export default () => {
  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSagas);

  return { store, persistor };
};
