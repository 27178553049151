import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ApplicationState } from "../../store";

// actions
import { actions } from "../../store/modules/messages/actions";

// selectors
import { messagesByChatId } from "../../store/modules/messages/selectors";
import { userIdAuthenticated } from "../../store/modules/auth/selectors";
import { getDetailsChatById } from "../../store/modules/chats/selectors";
import { PaginateById } from "../../store/modules/chats/types";

export const ContainerMessages = (Component) => {
    const Container = () => {
        const dispatch = useDispatch();
        const { id } = useParams<{ id: string }>();
        
        const messages = useSelector((state: any) => messagesByChatId(state, id));      

        const userId = useSelector(userIdAuthenticated);
        const currentChat = useSelector((state: ApplicationState) => getDetailsChatById(state, id));

        const paginateChat: PaginateById = useSelector((state: ApplicationState) => state.chats.paginateById[id]);

        const [isLoading, setIsLoading] = useState(false);
        const [openPiker, setOpenPiker] = useState(false);

        useEffect(() => {
            handleLoadMessages();
        }, []);

        const handleSubmit = (data) => {
            if (!data.text.trim() && !data.file) return;

            if (openPiker) setOpenPiker(false);

            dispatch(
                actions.create({
                    ...data,
                    text: data.text.trim(),
                    chatId: id,
                    handleEnableLoading,
                    handleDisableLoading,
                }),
            );
        };

        const handleLoadMessages = () => {
            dispatch(actions.load({ chatId: id }));
        };

        const handleNextPage = () => {
            const { currentPage, lastPage } = paginateChat;

            if (currentPage === lastPage) return;

            const nextPage = currentPage + 1;

            dispatch(actions.load({ chatId: id, page: nextPage }));
        };

        const handleEnableLoading = () => setIsLoading(true);
        const handleDisableLoading = () => setIsLoading(false);

        const hasNextPage = paginateChat?.currentPage < paginateChat?.lastPage;

        return (
            <Component
                payload={{
                    data: {
                        messages,
                        userId,
                        currentChat,
                        paginateChat,
                        hasNextPage,
                        isLoading,
                        openPiker
                    },
                    actions: {
                        submit: handleSubmit,
                        nextPage: handleNextPage,
                        setOpenPiker,
                    },
                }}
            />
        );
    };

    return Container;
};
