import React from "react";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
    border?: boolean;
    ["flex-column"]?: boolean;
}

import { Container } from "./styles";

const Card: React.FC<Props> = ({ children, ...rest }) => {
    return <Container {...rest}> {children} </Container>;
};

export default Card;
