import { put, call, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import { types } from "./types";

import history from "../../../utils/history";

import { postmessageChannel } from "../../../hooks/broadcastChannelTabs";

import { tokenAuthenticated } from "./selectors";

import { createSocketConnection, socketInstance } from "../../../service/socket";

import api from "../../../service/api";
import { actions } from "./actions";

function* login(action) {
    const { payload } = action;

    try {
        const response = yield call(api.post, "/auth/login", payload);

        if (response.data.error) {
            yield put({ type: types.AUTH_LOGIN_FAILURE });
            return;
        }

        yield call(postmessageChannel);

        const authenticate = yield put({ type: types.AUTH_LOGIN_SUCCESS, payload: response.data });

        yield (api.defaults.headers["Authorization"] = `Bearer ${response.data.token}`);

        if (action?.payload?.initSync && authenticate?.payload?.user?.isAdmin) {
            yield history.push("/users", { isFirstAccess: true });

        } else {
            yield history.push("/", { filter: "all" })
        }
    } catch (error) {
        yield put({ type: types.AUTH_LOGIN_FAILURE });
    }
}

function* logout() {
    try {
        yield put({
            type: types.AUTH_LOGOUT_SUCCESS,
        });

        yield call(postmessageChannel);

        yield history.push("/login");
    } catch (error) {
        yield put({ type: types.AUTH_LOGOUT_FAILURE });
    }
}

function* register(action) {
    const { payload } = action;

    try {
        const response = yield call(api.post, "/contacts", payload);

        yield call(toast, "Registro feito com sucesso", {
            type: "success",
        });

        yield put({ type: types.AUTH_REGISTER_SUCCESS });
        yield put(actions.login({ email: payload.email, password: payload.password }));
    } catch (error) {
        yield put({ type: types.AUTH_REGISTER_FAILURE });
    }
}

function* reLogin() {
    const token = yield select(tokenAuthenticated);

    if (!token) return;
    if (["/login", "/register", "/onboard"].includes(history.location.pathname)) return;

    try {
        yield (api.defaults.headers["Authorization"] = `Bearer ${token}`);

        yield call(api.post, "/auth/check-token");
        yield call(connectSocket);
    } catch (error) {
        yield history.push("/onboard");

        yield call(toast, "Necessário estar autenticado", {
            type: "error",
        });
    }
}

function* me(action) {
    const { type, payload } = action;

    const response = yield call(api.get, "/auth/me");

    yield put({
        type: types.AUTH_ME_SUCCESS,
        payload: {
            user: response.data,
        },
    });
}

// socket
function* connectSocket() {
    const token = yield select(tokenAuthenticated);

    if (!token) return;

    yield call(createSocketConnection, token);

    yield put({ type: types.SOCKET_CONNECTED });
}

function* disconnetSocket() {
    const socket = yield call(createSocketConnection);

    yield socket.disconnect();
    delete socketInstance.ws;

    // disconnect socket
    yield put({ type: types.SOCKET_DISCONNECTED });
}

export default {
    login,
    logout,
    reLogin,
    register,

    connectSocket,
    disconnetSocket,
    me,
};
