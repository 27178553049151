import { combineReducers } from "redux";

import { reducers as reducerAuth } from "./auth";
import { reducers as reducerChats } from "./chats";
import { reducers as reducerContacts } from "./contacts";
import { reducers as reducerMessages } from "./messages";
import { reducers as reducerWorkspace } from "./workspaces";

const rootReducers = combineReducers({
    auth: reducerAuth,
    chats: reducerChats,
    contacts: reducerContacts,
    messages: reducerMessages,
    workspaces: reducerWorkspace,
});

export default rootReducers;
