import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ApplicationState } from "../../store";
import { userIdAuthenticated } from "../../store/modules/auth/selectors";

// actions
import { actions } from "../../store/modules/workspaces/actions";

// selectors
import { data, getAllUnreadMessagesFromWorkspace, getPage } from "../../store/modules/workspaces/selectors";

export const ContainerChannels = (Component) => {
  const Container = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
      handleLoad();
    }, []);

    const handleLoad = () => {
      dispatch(actions.load({ page: 1 }));
    };

    const workspaces = useSelector(data);

    const workspacePage = useSelector((state: ApplicationState) => getPage(state));

    const userId = useSelector(userIdAuthenticated);

    const allUnread = useSelector((state: ApplicationState) => getAllUnreadMessagesFromWorkspace(state, userId));

    const handleSetCurrentWorkspace = (workspace) => {
      dispatch(actions.setCurrentWorkspace(workspace));
      history.push(`/channels/${workspace.id}`);
    };

    const handleNextPage = () => {
      const { currentPage, lastPage } = workspacePage;
      if (currentPage === lastPage) return;
      const nextPage = currentPage + 1;
      dispatch(actions.load({ page: nextPage }));
    };

    const hasNextPage = workspacePage?.currentPage < workspacePage?.lastPage && workspaces.length < workspacePage.total;

    return (
      <Component
        payload={{
          data: {
            allUnread,
            workspaces: workspaces,
            hasNextPage,
            total: workspacePage.total,
          },
          actions: {
            nextPage: handleNextPage,
            setCurrentWorkspace: handleSetCurrentWorkspace,
          },
        }}
      />
    );
  };

  return Container;
};
