import styled from "styled-components";

interface Props {
  show?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 66px);

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .wrapper-content {
    padding: 0px 16px ;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .wrapper-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }

  .wrapper-itens {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
  }

  .wrapper-button {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
  }
  .css-861lvf-Control{
    overflow-y: auto;
  }
  .wrapper-content-user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 8px 0px;
    .info-user {
      padding-left: 8px;
      width: 100%;
    }

    button {
      background-color: transparent;
    }
  }
`;

export const MemberShow = styled.section`
  padding-left: 17px;
  
  cursor: pointer;

  label {
    font-size: 16px;
    font-weight: 400;
  }

  .members-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 700;
    }

    > img {
      width: 17px;
      height: 17px;

      cursor: pointer;
      filter: invert(49%) sepia(72%) saturate(7373%) hue-rotate(177deg) brightness(102%) contrast(86%);
    }
  }
`;

