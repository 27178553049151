import React from "react";

import { Container } from "./styles";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarev: React.FC<Props> = ({ children, ...rest }) => {
    return <Container {...rest}> </Container>;
};

export default Textarev;
