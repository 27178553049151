import React from "react";

import { Link, useHistory } from "react-router-dom";

// container
import { ContainerClass } from "./container";

// styles
import { Container } from "./styles";

// types
import { Workspaces } from "../../store/modules/workspaces/types";

// components
import { Default } from "../../components/Layout";
import { Title } from "../../components/Fonts";
import Avatar from "../../components/Avatar";
import Dropdown from "../../components/Dropdown";
import Card from "../../components/Card";
import { Button } from "../../components/Button";
import Canais from "../../assets/icons/no-services.svg";
interface Props {
  payload: {
    data: {
      hasNextPage: boolean;
      workspaces: Workspaces[];
    };
    actions: {
      destroy(data): void;
      handleNextPage(): void;
    };
  };
}

const Class: React.FC<Props> = ({ payload }) => {
  const { workspaces, hasNextPage } = payload.data;
  const { destroy, handleNextPage } = payload.actions;

  const history = useHistory();

  const handleHistoryPath = (path) => {
    history.push(path);
  };

  return (
    <Default>
      <Container>
        <div className="wrapper-content">
          {!workspaces.length ? (
            <div className="wrapper-no-channels">
              <div className="wrapper-icon">
                <img src={Canais}></img>
              </div>
              <div className="wrapper-title">
                <Title fullWidth isArchived>
                  Não existem canais criados
                </Title>
              </div>
            </div>
          ) : (
            <div className="wrapper-body">
              {workspaces.map((item) => (
                <Card border={false} key={item.id}>
                  <Avatar name={item.name} />
                  <div className="wrapper">
                    <div className="title">
                      <Title title={item.name}>{item.name}</Title>
                    </div>
                    <Dropdown
                      items={[
                        {
                          label: "Editar",
                          handleClick: () => handleHistoryPath(`/class/${item.id}/edit`),
                        },
                        {
                          label: "Excluir",
                          handleClick: () => destroy(item.id),
                        },
                      ]}
                    ></Dropdown>
                  </div>
                </Card>
              ))}
            </div>
          )}
        </div>
        <div className="load-more">
          {hasNextPage && <Link to="#" onClick={handleNextPage}>Carregar mais</Link>}
          </div>
        <div className="wrapper-btn">
          <Button onClick={() => handleHistoryPath("/class/create")}> Criar canal </Button>
        </div>
      </Container>
    </Default>
  );
};

export default ContainerClass(Class);
