import styled from "styled-components";

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 32px;

    background: #007dab;

    color: #fff;
    font-weight: 500;
    font-size: 13px;

    transition: 0.2s;

    > img {
        width: 15px;
        height: 15px;

        margin-right: 7px;
    }

    :hover {
        background: rgba(0, 125, 171, 0.5);
    }
`;
