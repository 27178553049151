import { types } from "./types";

const {
    CHATS_LOAD,
    CHATS_SHOW,
    CHATS_CREATE,
    CHATS_UPDATED,
    CHATS_DESTROY,
    SET_CURRENT_CONTACT,
    CHATS_ADD_MEMBER,
    CHATS_REMOVE_MEMBER,
    SET_PAGE
} = types;

interface Result {
    type: string;
    payload?: any;
}

interface Actions {
    load(): Result;
    show(id): Result;
    create(data): Result;
    update(data): Result;
    destroy(data): Result;
    setCurrentContact(data): Result;
    addMember(data): Result;
    removeMember(data): Result;
    setPage(data): Result;
}

export const actions: Actions = {
    load: () => ({ type: CHATS_LOAD }),
    show: (id) => ({ type: CHATS_SHOW, payload: id }),
    create: (data) => ({ type: CHATS_CREATE, payload: data }),
    update: (data) => ({ type: CHATS_UPDATED, payload: data }),
    destroy: (data) => ({ type: CHATS_DESTROY, payload: data }),
    setCurrentContact: (data) => ({ type: SET_CURRENT_CONTACT, payload: data }),
    addMember: (data) => ({ type: CHATS_ADD_MEMBER, payload: data }),
    removeMember: (data) => ({ type: CHATS_REMOVE_MEMBER, payload: data }),
    setPage: (data) => ({ type: SET_PAGE, payload: data })
};
