import { types } from "./types";

const { AUTH_LOGIN, AUTH_LOGOUT, AUTH_REGISTER, AUTH_ME } = types;

interface Result {
    type: string;
    payload?: any;
}

interface Actions {
    login(data): Result;
    logout(): Result;
    register(data): Result;

    me(): Result;
}

export const actions: Actions = {
    login: (data) => ({
        type: AUTH_LOGIN,
        payload: data,
    }),
    logout: () => ({
        type: AUTH_LOGOUT,
    }),
    register: (data) => ({
        type: AUTH_REGISTER,
        payload: data,
    }),

    me: () => ({ type: AUTH_ME }),
};
