import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import useDebounce from "../../utils/useDebounce";

import { ContainerUsers } from "./container";

import { Container } from "./styles";

import ScrollToBottom from "react-scroll-to-bottom";

// types
import { Contact } from "../../store/modules/chats/types";
// components
import { Default } from "../../components/Layout";
import { Title, Email } from "../../components/Fonts";
import Avatar from "../../components/Avatar";
import Card from "../../components/Card";

interface Props {
  payload: {
    data: {
      contacts: Contact[];
      paginate: any;
    };
    actions: {
      newChat(contactId, webhookId): void;
      searchMember(data): void;
    };
  };
}

const NewChat: React.FC<Props> = ({ payload }) => {
  const { searchMember, newChat } = payload.actions;
  const { contacts, paginate } = payload.data;

  const history = useHistory();

  const [memberName, setMemberName] = useState("");

  const debounceChange = useDebounce(searchMember);

  const handleNewChat = (contactId, webhookId) => {
    newChat(contactId, webhookId);
  };

  useEffect(() => {
    // @ts-ignore
    if (history.location.state?.search) {
      // @ts-ignore
      setMemberName(history.location.state?.search);
    }
  }, [history.location]);

  useEffect(() => {
    if (memberName) {
      debounceChange({ name: memberName });
    }
  }, [memberName]);

  const nextPage = () => {
    if (paginate.currentPage < paginate.lastPage) {
      searchMember({ name: memberName, page: paginate.currentPage + 1, keep: true });
    }

    
  };
  
  return (
    <Default>
      <Container>
        {
          <div className="content">
            {!contacts.length && (
              <div className="wrapper-no-contacts">
                <Title fullWidth isArchived>
                  Nenhum contato encontrado!
                </Title>
              </div>
            )}

            <section className="list-users">
              {contacts.length !== 0 &&
                contacts?.map((contact) => (
                  <Card key={contact?.id} onClick={() => handleNewChat(contact.id, contact.webhookId)}>
                    <Avatar name={contact?.name} />
                    <section className="profile-data">
                      <Title title={contact?.name}>{contact?.name}</Title>
                      <Email>{contact?.email}</Email>
                    </section>
                  </Card>
                ))}
              <ScrollToBottom mode="bottom" className="scrollcp">
                {paginate.currentPage < paginate.lastPage && contacts.length >= 3 && (
                  <p className="load-more" onClick={nextPage}>
                    Carregar mais
                  </p>
                )}
              </ScrollToBottom>
            </section>
          </div>
        }
      </Container>
    </Default>
  );
};

export default ContainerUsers(NewChat);
