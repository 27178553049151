import { types } from "./types";

const { MESSAGES_LOAD, MESSAGES_CREATE, MESSAGES_UPDATED, MESSAGES_DESTROY, MESSAGES_LOAD_MORE } = types;

interface Result {
    type: string;
    payload?: any;
}

interface Actions {
    load(data): Result;
    create(data): Result;
    update(data): Result;
    destroy(data): Result;
    loadMore(data): Result;
}

export const actions: Actions = {
    load: (data) => ({ type: MESSAGES_LOAD, payload: data }),
    create: (data) => ({ type: MESSAGES_CREATE, payload: data }),
    update: (data) => ({ type: MESSAGES_UPDATED, payload: data }),
    destroy: (data) => ({ type: MESSAGES_DESTROY, payload: data }),
    loadMore: (data) => ({ type: MESSAGES_LOAD_MORE, payload: data }),
};
