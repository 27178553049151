import { createSelector } from "reselect";

import { Workspaces } from "./types";

import { getElements, getElementById } from "../common/selectors";
import { ApplicationState } from "../../index";

export const data = (state: ApplicationState) => getElements<Workspaces>(state.workspaces);

export const getPage = (state: ApplicationState) => state.workspaces.reducerPage;

export const getCurrentWorkspaceById = (state: ApplicationState, workspaceId: string) =>
  getElementById(state.workspaces.byId, workspaceId);

export const getCurrentWorksapce = (state: ApplicationState) => state.workspaces.currentWorkspace;

export const getChatsIncludeUser = (state: ApplicationState, workspaceId: string, userId: string) => {
  const workspace: Workspaces = getCurrentWorkspaceById(state, workspaceId);

  return workspace.chats.filter((chat) => chat.contacts.some((contact) => contact.id === userId));
};

export const getAllUnreadMessagesFromWorkspace = createSelector(
  data,
  (_, userId) => userId,
  (workspaces, userId) => {
    return workspaces.reduce((acc, cur) => {
      const { chats } = cur;

      let unread = 0;

      chats.forEach((chat) => {
        const checkUserAuthenticated = chat.contacts.find((contact) => contact.id === userId);
        if (checkUserAuthenticated) {
          unread += checkUserAuthenticated.informations.unread;
        }
      });

      return { ...acc, [cur.id]: unread };
    }, {});
  },
);
