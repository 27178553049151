import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 66px);
  overflow-y: auto;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .content {
    height: 100%;
    width: 100%;
  }

  .wrapper-no-contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }

  .search-user {
    padding: 8px 16px;
    width: 100%;
  }

  .list-users {
    width: 100%;
    height: 100%;
    overflow: auto;

    .profile-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 7px;
      width: 100%;

      > .wrapper-badge {
        display: flex;
      }

      > .wrapper-message {
        display: flex;
        align-items: center;

        p:nth-child(2) {
          margin-left: 7px;
        }
      }
    }

    .btn-toogle-status {
      height: 20px;
      width: 20px;
      max-height: 20px;
      max-width: 20px;
      min-height: 20px;
      min-width: 20px;

      background-color: transparent;
      border-radius: 7px;
      border: 2px solid #cccccc;

      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 12px;
      }
    }

    .remove {
      border: 2px solid #31a24c;
    }

    .load-more {
      color: #52658c;
      text-align: center;
      cursor: pointer;
      margin: 8px 0;

      transition: filter 0.2;

      :hover {
        filter: brightness(0.8);
      }
    }
  }

  .wrapper-btn {
    padding: 16px;
    width: 100%;
  }
`;
