import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ContainerGroups } from "./container";

import { Container } from "./styles";

// types
import { Workspaces } from "../../../store/modules/workspaces/types";
import { Contact } from "../../../store/modules/contacts/types";
import { Chat } from "../../../store/modules/chats/types";

// components
import { Default } from "../../../components/Layout";
import { Subtitle, Title } from "../../../components/Fonts";
import CardScroll from "../../../components/CardScroll";
import Avatar from "../../../components/Avatar";
import { Select, Text, SelectMulti } from "../../../components/Input";
import { Button } from "../../../components/Button";
import useDebounce from "../../../utils/useDebounce";

import searchIcon from "../../../assets/icons/search.svg";

import addUser from "../../../assets/icons/add-user-group.svg";
import removeUser from "../../../assets/icons/remove-user-group.svg";

interface Props {
  payload: {
    data: {
      contacts: Contact[];
      workspaces: Workspaces[];
      contactsAdmin: Contact[];
      isEditing: boolean;
      previousChat: Chat;
      disable: boolean;
    };
    actions: {
      submit(data): void;
      searchMember(data): Contact[];
      update(data): void;
      setDisable(data): void;
    };
  };
}

const GroupsCreate: React.FC<Props> = ({ payload }) => {
  const { submit, searchMember, update, setDisable } = payload.actions;
  const { workspaces, contacts, contactsAdmin, isEditing, previousChat, disable } = payload.data;

  const [members, setMembers] = useState<{ name: string; id: string; email: string; }[]>([]);
  const [temp, setTemp] = useState<{ name: string; id: string; email: string; }[]>([]);

  const debounceChange = useDebounce(searchMember);

  const formik = useFormik({
    initialValues: {
      name: previousChat?.name || "",
      workspaceId: previousChat?.workspace?.id || "",
      memberName: "",
      adminIds: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .strict()
        .trim("Digite um nome válido!")
        .required("Campo nome obrigatório!")
        .min(3, "O campo nome deve ter no mínimo 3 caracteres")
        .max(255, "O campo nome deve ter no máximo 255 caracteres"),
      workspaceId: Yup.string().required("Campo canais obrigatório!"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setDisable(formik.isSubmitting);
      const membersIds = members.map((item) => item.id);

      if (isEditing) {
        update({ ...values, contactsIds: membersIds });
        return;
      }

      submit({ ...values, contactsIds: membersIds });
    },
  });

  useEffect(() => {
    if (!formik.values.memberName) {
      setTemp([]);

      return;
    }

    debounceChange(formik.values.memberName);
  }, [formik.values.memberName]);

  useEffect(() => {
    if (previousChat) {
      const admins = previousChat.contacts.filter((item) => item.informations.isAdmin);

      formik.setFieldValue(
        "adminIds",
        admins.map((item) => ({ label: item.name, value: item.id })),
      );
    }
  }, [previousChat]);

  useEffect(() => {
    if (isEditing && previousChat) {
      setMembers(previousChat.contacts.filter((contact) => contact.status !== "disabled"));
    }
  }, []);

  useEffect(() => {
    if (contacts.length) {
      setTemp(contacts);
    } else {
      setTemp([]);
    }
  }, [contacts]);

  const handleAddMember = (data) => {
    if (members.some((item) => item.id === data.id)) {
      return setMembers(members.filter((item) => item.id !== data.id));
    }

    setMembers([...members, data]);
  };

  const handleIncludeMember = (id) => {
    return members.some((item) => item.id === id);
  };

  const worksapceOptions = workspaces.map((item) => ({ label: item.name, value: item.id }));
  const adminOptions = contactsAdmin.map((item) => ({ label: item.name, value: item.id }));

  return (
    <Default>
      <Container>
        <form onSubmit={formik.handleSubmit} className="wrapper-content" id="formGroups">
          <div className="wrapper-body">
            <div className="wrapper-itens">
              <Text
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Nome"
                placeholder="Nome do grupo"
              />
            </div>
              {formik.errors.name && formik.touched.name ? <Subtitle fullWidth error>{formik.errors.name}</Subtitle> : null}
            <div className="wrapper-itens">
              <Select
                name="workspaceId"
                onChange={formik.handleChange}
                value={formik.values.workspaceId}
                label="Canais"
                disabled={isEditing}
                items={worksapceOptions}
              ></Select>
            </div>
            {formik.errors.workspaceId && formik.touched.workspaceId ? (
                <Subtitle fullWidth error>{formik.errors.workspaceId}</Subtitle>
              ) : null}
            <div className="wrapper-itens">
              <SelectMulti
                name="adminIds"
                label="Administradores do grupo"
                items={adminOptions}
                value={formik.values.adminIds}
                handleChange={(value) => formik.setFieldValue("adminIds", value)}
              ></SelectMulti>
              {formik.errors.adminIds && formik.touched.adminIds ? (
                <Subtitle error>{formik.errors.adminIds}</Subtitle>
              ) : null}
            </div>

            {!isEditing && (
              <>
                <div className="wrapper-itens">
                  <Text
                    name="memberName"
                    onChange={formik.handleChange}
                    value={formik.values.memberName}
                    label="Integrantes"
                    placeholder="Pesquise um integrante"
                  />
                </div>
                <div className="wrapper-itens">
                  {!!contacts.length && (
                    <CardScroll>
                      {contacts.map((item) => (
                        <div className="wrapper-content-user" key={item.id}>
                          <Avatar name={item.name} />
                          <div className="info-user">
                            <section>
                              <Title title={item.name}>{item.name}</Title>
                              <Subtitle> {item.email} </Subtitle>
                            </section>
                          </div>
                          <button type="button" onClick={() => handleAddMember(item)}>
                            {handleIncludeMember(item.id) ? (
                              <img src={removeUser} alt="" />
                            ) : (
                              <img src={addUser} alt="" />
                            )}
                          </button>
                        </div>
                      ))}
                    </CardScroll>
                  )}
                </div>
              </>
            )}

            {isEditing && (
              <>
                <div className="members-wrapper">
                  <p> {members.length > 1 ? `${members.length} integrantes` : `${members.length} integrante`} </p>
                </div>

                <div className="wrapper-itens">
                  <Text
                    name="memberName"
                    onChange={formik.handleChange}
                    value={formik.values.memberName}
                    label="Integrantes"
                    placeholder="Pesquise um integrante"
                  />
                </div>
                
                <div className="wrapper-itens">
                  {(!!members.length && (
                      <CardScroll>
                        {(temp.length ? temp : members).map((item) => (
                          <div className="wrapper-content-user" key={item.id}>
                            <Avatar name={item.name} />
                            <div className="info-user">
                              <section>
                                <Title title={item.name}>{item.name}</Title>
                                <Subtitle> {item.email} </Subtitle>
                              </section>
                            </div>
                            <button type="button" onClick={() => handleAddMember(item)}>
                              {handleIncludeMember(item.id) ? (
                                <img src={removeUser} alt="" />
                              ) : (
                                <img src={addUser} alt="" />
                              )}
                            </button>
                          </div>
                        ))}
                      </CardScroll>
                    ))}
                </div>
              </>
            )}
          </div>
        </form>
          <div className="wrapper-button">
            <Button type="submit" disabled={disable} form="formGroups">
              {isEditing ? "Atualizar" : "Salvar"} 
            </Button>
          </div>
      </Container>
    </Default>
  );
};

export default ContainerGroups(GroupsCreate);
