import { createSelector } from "reselect";

import { Contact } from "./types";

import { getElements, getElementById } from "../common/selectors";
import { ApplicationState } from "../../index";

export const data = (state: ApplicationState) => getElements<Contact>(state.contacts);

export const getCurrentContactById = (state: ApplicationState, contactId: string) =>
    getElementById(state.contacts.byId, contactId);

export const isContactSynced = (state: ApplicationState, contactId: string) => {
    return !!state.contacts.byId[contactId];
};
