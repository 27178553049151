import styled from "styled-components";

export const Container = styled.span`
    background: rgba(82, 101, 140, 0.15);;
    border-radius: 50%;
    color: #52658C;
    
    padding: 0 5px;
    font-size: 13px;
    min-width: 22px;
    width: auto;
    height: auto;
    min-height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
`;
