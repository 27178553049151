import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { actions } from "../../store/modules/chats/actions";

//hooks
import { useAsync } from "../../hooks/useAsync";

//resources
import { chatsResource } from "../../resources/chats/api";

// selectors
import { userIdAuthenticated } from "../../store/modules/auth/selectors";
import { dataChats } from "../../store/modules/chats/selectors";

// types
import { Chat } from "../../store/modules/chats/types";

export const ContainerHome = (Component) => {
  const Container = () => {
    const dispatch = useDispatch();
    const userAuthId = useSelector(userIdAuthenticated);
    const [data, setData] = useState<Chat[]>([]);
    const { execute, value: chatsFiltered } = useAsync(chatsResource.searchChats);

    const chats = useSelector(dataChats);

    useEffect(() => {
      handleLoadChats();
    }, []);

    useEffect(() => {
      if (chats) setData(chats);
    }, [chats]);

    /**
     * Adiciona os chats filtrados via api no estado
     */
    useEffect(() => {
      if (chatsFiltered) setData(chatsFiltered);
    }, [chatsFiltered]);

    const handleLoadChats = () => {
      dispatch(actions.load());
    };

    const isArchived = (chat) => {
      return chat.contacts.some((contact) => contact.status === "disabled" || contact.deletedAt);
    };

    const handleSetCurrentContact = (chat) => {
      const chatJoin = chat.contacts.find((c) => c.id !== userAuthId);
      const contactId = !chat.isGroup ? (isArchived(chat) ? userAuthId : chatJoin?.id) : null;
      dispatch(actions.setCurrentContact({ chatId: chat.id, contactId }));
    };

    const handleUnread = (chat, userId) => {
      return chat?.contacts?.find((contact) => contact.id === userId)?.informations?.unread;
    };

    return (
      <Component
        payload={{
          data: {
            chats: data.filter((chat) => {
              if (!chat.lastMessageId && !chat.isGroup) return false;
              return true;
            }),
            userIdAuthenticated: userAuthId,
          },
          actions: {
            setCurrentContact: handleSetCurrentContact,
            unread: handleUnread,
            searchChats: (filterData) => execute(filterData || { filter: "all" }),
          },
        }}
      />
    );
  };

  return Container;
};
