import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: calc(100vh - 66px);

    padding: 16px;
    background: #f5f9ff;

    overflow-y: auto;

    section ul li {
        margin-left: 15px;
    }

    section div.wrapper-link {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            color: #bab86c;
            font-weight: 600;
            font-size: 8px;
        }
    }

    section p {
        margin: 7px 0;
    }
`;
