import { Reducer } from "redux";
import { StateContacts, types } from "./types";

import { createTreeAllId, createTreeById, insertElementAllId, insertElementById } from "../common/selectors";

import { types as typesAuth } from "../auth/types";

const { CONTACTS_LOAD_SUCCESS, CONTACTS_SHOW_SUCCESS, CONTACTS_ON_SOCKET } = types;

const initialState: StateContacts = {} as StateContacts;

const reducerById: Reducer = (state = {}, action): { [key: string]: any } => {
    const { type, payload } = action;

    switch (type) {
        case CONTACTS_LOAD_SUCCESS:
            return createTreeById(state, payload);
        case CONTACTS_SHOW_SUCCESS:
        case CONTACTS_ON_SOCKET:
            return insertElementById(state, payload);
        case typesAuth.AUTH_LOGOUT_SUCCESS:
            return {};
        case types.CONTACTS_ENABLE_SUCCESS:
            return {
                ...state,
                [payload]: {
                    ...state[payload],
                    status: "enabled",
                },
            };
        case types.CONTACTS_DISABLE_SUCCESS:
            return {
                ...state,
                [payload]: {
                    ...state[payload],
                    status: "disabled",
                },
            };
        default:
            return state;
    }
};

const reducerAllId: Reducer = (state = [], action): string[] => {
    const { type, payload } = action;

    switch (type) {
        case CONTACTS_LOAD_SUCCESS:
            return createTreeAllId(state, payload);
        case CONTACTS_SHOW_SUCCESS:
        case CONTACTS_ON_SOCKET:
            return insertElementAllId(state, payload);
        case typesAuth.AUTH_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
};

export const reducers: Reducer<StateContacts> = (state = initialState, actions) => ({
    byId: reducerById(state.byId, actions),
    allId: reducerAllId(state.allId, actions),
});
