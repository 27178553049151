import styled from "styled-components";

export const Container = styled.div`
  background: #fff;

  > .wrapper-content {
    width: 100%;
    height: calc(100vh - 66px);
  }

  > .wrapper-content .content {
    width: 100%;
    height: 100%;

    overflow-y: auto;

    .no-channels {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > div .profile-data {
      width: 100%;

      margin-left: 7px;

      display: flex;
      justify-content: space-between;

      > .wrapper-info {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      > .more-actions {
        background: transparent;

        > img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .wrapper-btn {
    padding: 16px;
    width: 100%;
  }
`;
