import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";

import { types } from "./types";

import api from "../../../service/api";

import history from "../../../utils/history";

import { actions as actionsWorkspaces } from "./actions";

function* load(action) {
  const { payload } = action;

  try {
    const response = yield call(api.get, `/workspaces`, {
      params: {
        order: [["name", "asc"]],
        page: payload?.page || 1,
      },
    });
    
    const dataPaginated = {
      currentPage: response.data.currentPage,
      from: response.data.from,
      lastPage: response.data.lastPage,
      limit: response.data.limit,
      skip: response.data.skip,
      to: response.data.to,
      total: response.data.total,
    };

    yield put(actionsWorkspaces.setPage(dataPaginated));

    yield put({ type: types.WORKSPACES_LOAD_SUCCESS, payload: response.data.data });
  } catch (error) {
    yield put({ type: types.WORKSPACES_LOAD_FAILURE });
  }
}

function* showChats(action) {
  const { payload } = action;

  const { id } = payload;

  try {
    const response = yield call(api.get, `/workspaces/show-chats/${id}`);

    yield put({ type: types.WORKSPACES_SHOW_CHATS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.WORKSPACES_SHOW_CHATS_FAILURE });
  }
}

function* show(action) {
  const { payload } = action;

  const { id, query } = payload;

  try {
    const response = yield call(api.get, `/workspaces/${id}`, {
      params: query,
    });

    yield put({ type: types.WORKSPACES_SHOW_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.WORKSPACES_SHOW_FAILURE });
  }
}

function* create(action) {
  const { payload } = action;

  try {
    const response = yield call(api.post, `/workspaces`, payload);
    
    //@ts-ignore
    if(history.location.state?.noGroups) return

    yield call(toast.success, "Canal criado com sucesso!", {
      onClose: () => {
        history.push("/class");
      },
    });

    yield put({ type: types.WORKSPACES_CREATE_SUCCESS, payload: response.data.data });
  } catch (error) {
    yield put({ type: types.WORKSPACES_CREATE_FAILURE });
  }
}

function* update(action) {
  const { payload } = action;

  const { id } = payload;

  try {
    const response = yield call(api.put, `/workspaces/${id}`, payload);

    yield call(toast.success, "Canal atualizado com sucesso!", {
      onClose: () => {
        history.push("/class");
      },
    });

    yield put({ type: types.WORKSPACES_UPDATE_SUCCESS, payload: response.data.data });
  } catch (error) {
    yield put({ type: types.WORKSPACES_UPDATE_FAILURE });
  }
}

function* destroy(action) {
  const { payload } = action;

  const { id } = payload;

  try {
    const response = yield call(api.delete, `/workspaces/${id}`);

    if (response?.data?.status === 500) {
      yield call(toast.warn, "Nao foi possivel excluir, exclua os grupos primeiro!");
      return;
    }

    yield call(toast.success, "Canal deletado com sucesso!");

    yield put({ type: types.WORKSPACES_DESTROY_SUCCESS, payload: id });
  } catch (error) {
    yield put({ type: types.WORKSPACES_DESTROY_FAILURE });
  }
}

function* setPage(action) {
  const { payload } = action;

  yield put({ type: types.WORKSPACES_SET_PAGE, payload });
}

export default {
  load,
  show,
  showChats,
  create,
  update,
  destroy,
  setPage,
};
