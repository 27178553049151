import styled from "styled-components";

interface Props {
  maxWidth?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 36px;
  height: 36px;

  button {
    width: 100%;
    height: 100%;

    background: #f6f6f6;
    border-radius: 50%;

    transition: all 0.2s;

    img {
      width: 15px;
      height: 15px;
    }

    :hover {
      filter: brightness(0.95);
    }
  }
`;

export const WrapperItem = styled.div<Props>`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(82, 101, 140, 0.15);
  border-radius: 10px;
  z-index: 2;
  position: absolute;

  top: 0;
  right: 40px;
  width: ${({ maxWidth }) => (maxWidth ? "190px" : "160px")};

  transition: 0.2s;
`;

export const Item = styled.div`
  color: #52658c;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 8px;

  img {
    margin-right: 8px;
    width: 22px;
    height: 22px;
  }

  &:hover {
    background-color: rgba(82, 101, 140, 0.1);

    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
`;

export const TextItem = styled.span`
  color: #52658c;
  font-size: 14px;
  font-weight: 400;
`;

export const Label = styled.span`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-left: 8px;
  line-height: 3em;
`;
