import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 75px;
    min-height: 75px;

    background: #FCFCFC;
    border-bottom: 1px solid rgba(82, 101, 140, 0.15);
    padding: 0 16px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
    transition: 0.2s;

    :hover {
        background-color: rgba(82, 101, 140, 0.1);
    }
`;
