import React from "react";

import { Router } from "react-router";
import { Route, Switch } from "react-router-dom";

import history from "../utils/history";

// layout
import PrivateRoute from "./PrivateRoute";

// pages
import Onboard from "../pages/Onboard";
import Home from "../pages/Home";
import Channels from "../pages/Channels";
import Chats from "../pages/Chats";
import Messages from "../pages/Messages";
import Users from "../pages/Users";
import NewChat from "../pages/NewChat";
import Settings from "../pages/Settings";
import Groups from "../pages/Groups";
import Class from "../pages/Class";
import ClassCreate from "../pages/Class/Create";
import GroupsForm from "../pages/Groups/Form";
import Unauthorized from "../pages/Unauthorized";

const Routes: React.FC = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/unauthorized" component={Unauthorized} />
                <Route exact path="/onboard/:id/:token" component={Onboard} />
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/channels" component={Channels} />
                <PrivateRoute exact path="/channels/:id" component={Chats} />

                <PrivateRoute exact path="/chat/:id" component={Messages} />

                <PrivateRoute exact path="/users" component={Users} />

                <PrivateRoute exact path="/new-chat" component={NewChat} />

                <PrivateRoute exact path="/settings" component={Settings} />

                <PrivateRoute exact path="/class" component={Class} />
                <PrivateRoute exact path="/class/create" component={ClassCreate} />
                <PrivateRoute exact path="/class/:id/edit" component={ClassCreate} />

                <PrivateRoute exact path="/groups" component={Groups} />
                <PrivateRoute exact path="/groups/create" component={GroupsForm} />
                <PrivateRoute exact path="/groups/:id/edit" component={GroupsForm} />
            </Switch>
        </Router>
    );
};

export default Routes;
