export enum types {
    CONTACTS_LOAD = "@contact/CONTACTS_LOAD",
    CONTACTS_LOAD_SUCCESS = "@contact/CONTACTS_LOAD_SUCCESS",
    CONTACTS_LOAD_FAILURE = "@contact/CONTACTS_LOAD_FAILURE",

    CONTACTS_SHOW = "@contact/CONTACTS_SHOW",
    CONTACTS_SHOW_SUCCESS = "@contact/CONTACTS_SHOW_SUCCESS",
    CONTACTS_SHOW_FAILURE = "@contact/CONTACTS_SHOW_FAILURE",

    CONTACTS_CREATE = "@contact/CONTACTS_CREATE",
    CONTACTS_CREATE_SUCCESS = "@contact/CONTACTS_CREATE_SUCCESS",
    CONTACTS_CREATE_FAILURE = "@contact/CONTACTS_CREATE_FAILURE",

    CONTACTS_UPDATED = "@contact/CONTACTS_UPDATED",
    CONTACTS_UPDATED_SUCCESS = "@contact/CONTACTS_UPDATED_SUCCESS",
    CONTACTS_UPDATED_FAILURE = "@contact/CONTACTS_UPDATED_FAILURE",

    CONTACTS_DESTROY = "@contact/CONTACTS_DESTROY",
    CONTACTS_DESTROY_SUCCESS = "@contact/CONTACTS_DESTROY_SUCCESS",
    CONTACTS_DESTROY_FAILURE = "@contact/CONTACTS_DESTROY_FAILURE",

    CONTACTS_ON_SOCKET = "@contact/CONTACTS_ON_SOCKET",

    CONTACTS_ENABLE = "@contact/CONTACTS_ENABLE",
    CONTACTS_ENABLE_SUCCESS = "@contact/CONTACTS_ENABLE_SUCCESS",
    CONTACTS_ENABLE_FAILURE = "@contact/CONTACTS_ENABLE_FAILURE",

    CONTACTS_DISABLE = "@contact/CONTACTS_DISABLE",
    CONTACTS_DISABLE_SUCCESS = "@contact/CONTACTS_DISABLE_SUCCESS",
    CONTACTS_DISABLE_FAILURE = "@contact/CONTACTS_DISABLE_FAILURE",
    CONTATCS_ENABLE = "@contacts/CONTATCS_ENABLE",
    CONTATCS_LOAD_ENABLE = "@contacts/CONTATCS_LOAD_ENABLE",
}
export interface Informations {
    chatId: string;
    contactId: string;
    createdAt: Date;
    deletedAt: Date;
    id: number;
    isAdmin: boolean;
    unread: number;
    updatedAt: Date;
}
export interface Contact {
    id: string;
    name: string;
    phone: string;
    isAdmin: boolean;
    informations: Informations;
    email: string;
    status: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface StateContacts {
    allId: string[];
    byId: { [key: string]: any };
}
