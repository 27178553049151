import { Contact } from "../chats/types";

export enum types {
  WORKSPACES_LOAD = "@workspaces/WORKSPACES_LOAD",
  WORKSPACES_LOAD_SUCCESS = "@workspaces/WORKSPACES_LOAD_SUCCESS",
  WORKSPACES_LOAD_FAILURE = "@workspaces/WORKSPACES_LOAD_FAILURE",

  WORKSPACES_SET_PAGE = "@workspaces/WORKSPACES_SET_PAGE",

  WORKSPACES_SHOW = "@workspaces/WORKSPACES_SHOW",
  WORKSPACES_SHOW_SUCCESS = "@workspaces/WORKSPACES_SHOW_SUCCESS",
  WORKSPACES_SHOW_FAILURE = "@workspaces/WORKSPACES_SHOW_FAILURE",

  WORKSPACES_SHOW_CHATS = "@workspaces/WORKSPACES_SHOW_CHATS",
  WORKSPACES_SHOW_CHATS_SUCCESS = "@workspaces/WORKSPACES_SHOW_CHATS_SUCCESS",
  WORKSPACES_SHOW_CHATS_FAILURE = "@workspaces/WORKSPACES_SHOW_CHATS_FAILURE",

  WORKSPACES_CREATE = "@workspaces/WORKSPACES_CREATE",
  WORKSPACES_CREATE_SUCCESS = "@workspaces/WORKSPACES_CREATE_SUCCESS",
  WORKSPACES_CREATE_FAILURE = "@workspaces/WORKSPACES_CREATE_FAILURE",

  WORKSPACES_UPDATE = "@workspaces/WORKSPACES_UPDATE",
  WORKSPACES_UPDATE_SUCCESS = "@workspaces/WORKSPACES_UPDATE_SUCCESS",
  WORKSPACES_UPDATE_FAILURE = "@workspaces/WORKSPACES_UPDATE_FAILURE",

  WORKSPACES_DESTROY = "@workspaces/WORKSPACES_DESTROY",
  WORKSPACES_DESTROY_SUCCESS = "@workspaces/WORKSPACES_DESTROY_SUCCESS",
  WORKSPACES_DESTROY_FAILURE = "@workspaces/WORKSPACES_DESTROY_FAILURE",

  SET_CURRENT_WORKSPACE = "@workspaces/SET_CURRENT_WORKSPACE",
}

export interface Chats {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  contacts: Contact[];
}

export interface Workspaces {
  id: string;
  name: string;
  chats: Chats[];
  createdAt: Date;
  updatedAt: Date;
}

export interface WorkspacePaginate {
  currentPage: number;
  from: 0;
  lastPage: 1;
  limit: number;
  to: number;
  total: number;
}

export interface StateWorkspaces {
  allId: string[];
  byId: { [key: string]: any };
  currentWorkspace: Workspaces;
  reducerPage: WorkspacePaginate;
}
