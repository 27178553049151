import { types } from "./types";

interface Result {
  type: string;
  payload?: any;
}

interface Actions {
  load(data?): Result;
  show(data): Result;
  showChats(data): Result;
  create(data): Result;
  update(data): Result;
  destroy(data): Result;
  setCurrentWorkspace(data): Result;
  setPage(data): Result;
}

export const actions: Actions = {
  load: (data) => ({ type: types.WORKSPACES_LOAD, payload: data }),
  show: (data) => ({ type: types.WORKSPACES_SHOW, payload: data }),
  showChats: (data) => ({ type: types.WORKSPACES_SHOW_CHATS, payload: data }),
  create: (data) => ({ type: types.WORKSPACES_CREATE, payload: data }),
  update: (data) => ({ type: types.WORKSPACES_UPDATE, payload: data }),
  destroy: (data) => ({ type: types.WORKSPACES_DESTROY, payload: data }),
  setCurrentWorkspace: (data) => ({ type: types.SET_CURRENT_WORKSPACE, payload: data }),
  setPage: (data) => ({ type: types.WORKSPACES_SET_PAGE, payload: data }),
};
