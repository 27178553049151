import { Reducer } from "redux";
import { StateChats, types } from "./types";

import {
    createTreeAllId,
    createTreeById,
    insertElementAllId,
    insertElementById,
    deleteElementAllId,
} from "../common/selectors";

import { types as typesAuth } from "../auth/types";

const {
    CHATS_LOAD_SUCCESS,
    CHATS_SHOW_SUCCESS,
    CHATS_CREATE_SUCCESS,
    SET_CURRENT_CONTACT_SUCCESS,
    CHATS_ON_SOCKET,
    CHATS_REMOVED_SOCKET,
    CHATS_ADDED_SOCKET,

    CHATS_ADD_MEMBER_SUCCESS,
    CHATS_REMOVE_MEMBER_SUCCESS,

    SET_PAGE_SUCCESS,
} = types;

const initialState: StateChats = {} as StateChats;

const reducerById: Reducer = (state = {}, action): { [key: string]: any } => {
    const { type, payload } = action;

    switch (type) {
        case CHATS_LOAD_SUCCESS:
            return createTreeById(state, payload);
        case CHATS_CREATE_SUCCESS:
        case CHATS_SHOW_SUCCESS:
        case CHATS_ON_SOCKET:
        case CHATS_ADDED_SOCKET:
            return insertElementById(state, payload);
        case typesAuth.AUTH_LOGOUT_SUCCESS:
            return {};

        case types.CHATS_UPDATED_SUCCESS: {
            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    contacts: payload.contacts,
                },
            };
        }

        case CHATS_ADD_MEMBER_SUCCESS:
            return {
                ...state,
                [payload.chatId]: {
                    ...state[payload.chatId],
                    contacts: [...state[payload.chatId].contacts, ...payload.contacts],
                },
            };

        case CHATS_REMOVE_MEMBER_SUCCESS:
            return {
                ...state,
                [payload.chatId]: {
                    ...state[payload.chatId],
                    contacts: state[payload.chatId].contacts.filter((item) => item.id !== payload.contactId),
                },
            };

        default:
            return state;
    }
};

const reducerAllId: Reducer = (state = [], action): string[] => {
    const { type, payload } = action;

    switch (type) {
        case CHATS_LOAD_SUCCESS:
            return createTreeAllId(state, payload);
        case CHATS_CREATE_SUCCESS:
        case CHATS_SHOW_SUCCESS:
        case CHATS_ON_SOCKET:
        case CHATS_ADDED_SOCKET:
            return insertElementAllId(state, payload);
        case typesAuth.AUTH_LOGOUT_SUCCESS:
            return [];
        case CHATS_REMOVED_SOCKET:
        case types.CHATS_DESTROY_SUCCESS:
            return deleteElementAllId(state, payload);
        default:
            return state;
    }
};

const reducerCurrentContact: Reducer = (state = "", action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_CURRENT_CONTACT_SUCCESS:
            return payload;
        case typesAuth.AUTH_LOGOUT_SUCCESS:
            return "";
        default:
            return state;
    }
};

const reducerPaginateById: Reducer = (state = {}, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_PAGE_SUCCESS:
            return insertElementById(state, payload);
        default:
            return state;
    }
};

export const reducers: Reducer<StateChats> = (state = initialState, actions) => ({
    byId: reducerById(state.byId, actions),
    allId: reducerAllId(state.allId, actions),
    currentContact: reducerCurrentContact(state.currentContact, actions),
    paginateById: reducerPaginateById(state.paginateById, actions),
});
