import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loading from "../../components/Loading";

import { useAsync } from "../../hooks/useAsync";

import webhookResource from "../../resources/webhooks/api";

import { actions } from "../../store/modules/auth/actions";

import webhooksResource from "../../resources/webhooks/api";

export const ContainerOnboard = (Component) => {
    const Container = () => {
        const dispatch = useDispatch();
        const { id, token } = useParams<{ id: string; token: string }>();

        const { execute: createWebHook, value: valueCreateWebHook, error: errorCreateWebhook } = useAsync(
      webhookResource.create
    );
        const { execute: checkToken, value: authValue } = useAsync(webhookResource.checkToken);
        const { execute: SyncContacts, value: valueSyncContacts, error: errorSyncContact } = useAsync(
      webhooksResource.syncContact,
    );

        const [loading, setLoading] = useState(true);
        const [isSync, setIsSync] = useState(false);

        useEffect(() => {
            checkToken({ token });
        }, []);

        useEffect(() => {
            if (authValue) {
                dispatch(actions.login(JSON.parse(authValue)));
            }
        }, [authValue]);

        useEffect(() => {
            if (valueCreateWebHook) {
                if (valueCreateWebHook?.code === 1) {
                    dispatch(actions.login({ initSync: true, ...JSON.parse(valueSyncContacts) }));
                    setLoading(false);
                    return;
                }
                dispatch(actions.login(JSON.parse(valueSyncContacts)));
                setLoading(false);
            }
            setLoading(false);
        }, [valueSyncContacts]);

        const handleInit = async () => {
          setIsSync(true);
            await createWebHook({ accountId: id, token });
            await SyncContacts({ accountId: id, token });
          errorCreateWebhook || errorSyncContact ? setIsSync(false) : setIsSync(true);
        };

        if (loading) return <Loading />;

        return (
            <Component
                payload={{
                    data: {
                        user: JSON.parse(authValue),
                        isSync,
                    },
                    actions: {
                        init: handleInit,
                    },
                }}
            />
        );
    };

    return Container;
};
