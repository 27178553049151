import styled from "styled-components";

interface Props {
    fullHeight?: boolean;
}

export const Container = styled.div<Props>`
    width: 100%;
    max-height: ${(props) => (props.fullHeight ? '355px' : '200px' )};

    overflow-y: auto;

    padding: 8px 16px;

    border: 1px solid rgba(82,101,140,0.15);
    border-radius: 20px;

    ::-webkit-scrollbar-track {
        background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
        width: 6px;
        background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
        background: #52658c;
        border-radius: 2px;
    }
`;
