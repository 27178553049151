import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 40px ;
    
    > label {
        font-size: 16px;
        font-weight: 400;
        margin-left: 17px;
    }

    >div >div >div,
    >div >div >div >div,
    >div >div >div >div >div,
    >div >div >div >div >div >div {
        margin: 0;
        padding: 0;
    }
    
    .select__multi-value {
        margin-right: 8px;

        :last-child {
            margin: 0;
        }
    }

    span {
        width: 0;
    }
`;

export const DropdownIndicatorIcon = styled.img`
    width: 39px;
    height: 17px;
    cursor: pointer;
    filter: invert(49%) sepia(72%) saturate(7373%) hue-rotate(177deg) brightness(102%) contrast(86%);
`;
